import client from '../../app/axiosClient';

class TagAdapter {
  static async fetchAll() {
    const tags = await client.get('/tags');

    return tags.data;
  }

  static async create(body: any) {
    const tag = await client.post('/tag', body);

    return tag.data;
  }

  static async delete(id: number) {
    const tag = await client.delete('/tag/' + id);

    return tag.data;
  }
}

export default TagAdapter;
