import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Action from './Action';
import { Icons } from '../../../shared/components/Icon/Icon';
import { OverlayType } from '../../../app/Modal';
import { openOverlay } from '../../../app/store/overlaySlice';
import FileAdapter from '../../../shared/adapters/file';
import { RootState } from '../../../app/store/store';
import { DataType } from '../../../app/store/activeSlice';
import FolderAdapter from '../../../shared/adapters/folder';
import { formatName } from '../../../shared/utils/formatName';

interface OwnProps {
  id: number;
  name: string;
}

const mapStateToProps = (state: RootState) => ({
  type: state.active.type,
  selected: state.active.selected,
});

const mapDispatchToProps = {
  openOverlay,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

interface State {
  actions: {
    onClick: () => void;
    icon: Icons;
  }[];
}

class ActionMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      actions: [
        {
          onClick: () => this.onClick(OverlayType.UPDATE_DATA),
          icon: Icons.EDIT3,
        },
        {
          onClick: () => this.onDownloadClick(),
          icon: Icons.DOWNLOAD,
        },
        {
          onClick: () => this.onClick(OverlayType.DELETE_DATA),
          icon: Icons.TRASH2,
        },
        {
          onClick: () => this.onClick(OverlayType.UPDATE_GRANT),
          icon: Icons.SHARE2,
        },
        {
          onClick: () => this.onClick(OverlayType.MOVE_DATA),
          icon: Icons.MOVE,
        },
      ],
    };
  }

  onClick(type: OverlayType | null) {
    if (type === null) {
      return this.onDownloadClick();
    }

    this.props.openOverlay({
      overlayType: type,
      overlayData: {},
    });
  }

  async onDownloadClick() {
    const { suffix, name, previewSavedName } = this.props.selected;
    const isFile = this.props.type === DataType.FILE;

    const blob = isFile
      ? await FileAdapter.downloadFile(
          this.props.selected.accessURIs.downloadURI
        )
      : await FolderAdapter.downloadFolder(this.props.id);

    const url = URL.createObjectURL(blob);

    const downloadName = isFile
      ? formatName(name, suffix, previewSavedName)
      : this.props.name.replace(`.${this.props.selected.suffix}`, '.zip');

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', downloadName);

    a.click();
    URL.revokeObjectURL(url);
  }

  render() {
    return (
      <div className="details-action-menu">
        {this.state.actions.map((action, i) => (
          <Action icon={action.icon} onClick={() => action.onClick()} key={i} />
        ))}
      </div>
    );
  }
}

export default connector(ActionMenu);
