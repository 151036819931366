import React from 'react';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';

interface OwnProps {
  onClick: () => void;
}

type Props = OwnProps;

class BackButton extends React.Component<Props> {
  render() {
    return (
      <div
        className="utility-bar-add-button"
        onClick={() => this.props.onClick()}
      >
        <span className="utility-bar-icon">
          <Icon icon={Icons.CHEVRON_LEFT} size={32}></Icon>
        </span>
      </div>
    );
  }
}

export default BackButton;
