import './styles.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { OverlayType } from '../../../../app/Modal';
import {
  closeOverlay,
  openOverlay,
  openUploadQueue,
} from '../../../../app/store/overlaySlice';
import { RootState } from '../../../../app/store/store';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import OverlayBody from '../../../../shared/components/OverlayBody';
import OverlayHeader from '../../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../../shared/components/OverlayWrapper';
import OverlayFooter from '../../../../shared/components/OverlayFooter';
import Button from '../../../../shared/components/Button/Button';
import { enqueue } from '../../../../app/store/queueSlice';

const mapStateToProps = (state: RootState) => ({
  path: state.path.path,
  queue: state.queue,
  currentFolderId: state.folders.currentFolderId,
});

const mapDispatchToProps = {
  closeOverlay,
  enqueue,
  openOverlay,
  openUploadQueue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class CreateMenu extends React.Component<Props> {
  private fileChooser: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.fileChooser = React.createRef();
  }

  onCloseClick() {
    this.props.closeOverlay();
  }

  onNewFolderClick() {
    this.props.openOverlay({
      overlayType: OverlayType.ADD_FOLDER,
      overlayData: {},
    });
  }

  onNewFileClick() {
    this.fileChooser.current?.click();
  }

  onNewFiles() {
    console.log(this.fileChooser.current?.files);
    const filesCollection = this.fileChooser.current?.files;

    if (!filesCollection) {
      return;
    }

    const toEnqueue = [];

    for (let i = 0; i < filesCollection?.length; i++) {
      const file = filesCollection.item(i);

      if (!file) continue;

      const url = URL.createObjectURL(
        new Blob([file], {
          type: file.type,
        })
      );

      toEnqueue.push({
        name: file.name,
        size: file.size,
        type: file.type,
        parentFolderId: this.props.currentFolderId,
        url,
        progress: 0,
      });
    }

    this.props.enqueue({
      items: toEnqueue,
    });
    this.props.openUploadQueue();
    this.props.closeOverlay();
  }

  render() {
    const [context] = this.props.path;
    const subtitle = `v mapo "${context ? context.name : 'Moj disk'}"`;

    return (
      <OverlayWrapper className="">
        <OverlayHeader
          showDivider
          title="Dodaj"
          icon={Icons.PLUSCIRCLE}
          subtitle={subtitle}
        ></OverlayHeader>
        <OverlayBody>
          <div className="add-option" onClick={() => this.onNewFolderClick()}>
            <Icon icon={Icons.FOLDER} size={28}></Icon>
            <span className="add-option-text">Ustvari novo mapo</span>
          </div>
          <hr></hr>
          <div className="add-option">
            <Icon icon={Icons.FOLDER_PLUS} size={28}></Icon>
            <span className="add-option-text">Naloži mapo</span>
          </div>
          <div className="add-option" onClick={() => this.onNewFileClick()}>
            <Icon icon={Icons.FILE_PLUS} size={28}></Icon>
            <span className="add-option-text">Naloži datoteko</span>
            <input
              style={{
                display: 'none',
              }}
              type="file"
              multiple
              ref={this.fileChooser}
              onChange={() => this.onNewFiles()}
            />
          </div>
        </OverlayBody>
        <OverlayFooter>
          <Button text="Zapri" onClick={() => this.onCloseClick()} />
          <Button text="Končaj" onClick={() => this.onCloseClick()} primary />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(CreateMenu);
