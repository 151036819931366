import '../../shared/styles/Login.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../app/store/authSlice';
import { RootState } from '../../app/store/store';
import Button from '../../shared/components/Button/Button';
import Input from '../../shared/components/Input/Index';
import { getStoredAuthToken } from '../../shared/utils/token';
import { Icons } from '../../shared/components/Icon/Icon';
import useKeyPress from '../../shared/hooks/useKeyPress';

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const enterPressed = useKeyPress('Enter');

  const onLoginClick = () => {
    dispatch(
      login({
        username,
        password,
      })
    );
  };

  useEffect(() => {
    if (auth.token || getStoredAuthToken()) {
      navigate('/app');
    }
  }, [auth.token, navigate]);

  useEffect(() => {
    // Enter pressed
    if (enterPressed) {
      onLoginClick();
    }
  }, [enterPressed]);

  useEffect(() => {
    if (auth.userData !== null) {
      navigate('/app');
    }
  }, [auth.userData, navigate]);

  return (
    <div className="login">
      <div className="login-wrapper">
        <div className="title">
          {/* <Icon icon={Icons.KEY} size={64}></Icon> */}
          PRIJAVA
        </div>
        <Input
          name="username"
          type="text"
          icon={Icons.USER}
          placeholder="Vpiši uporabniško ime"
          setValue={(value) => setUsername(value)}
          value={username}
        ></Input>
        <Input
          name="password"
          icon={Icons.LOCK}
          type="password"
          placeholder="Vpiši geslo"
          setValue={(value) => setPassword(value)}
          value={password}
        ></Input>
        <Button onClick={() => onLoginClick()} text="Login" primary />
      </div>
    </div>
  );
};

export default Login;
