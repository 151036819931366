import React from 'react';
import Dropzone from '../../../../shared/components/Draggable/Dropzone';

import FolderListElement from './FolderListElement';

interface OwnProps {
  dropzone: boolean;
  folders: any[];
  showDetails: boolean;
  onFolderClick: (id: number) => void;
  onFolderDoubleClick: (id: number) => void;
  onFolderDetailsClick: (id: number) => void;
  onDrop?: (data: any) => void;
}

type Props = OwnProps;

class FolderList extends React.Component<Props> {
  render() {
    const folderElements = this.props.folders.map((folder, i) => (
      <FolderListElement
        key={i}
        showDetails={this.props.showDetails}
        folder={folder}
        onClick={(id) => this.props.onFolderClick(id)}
        onDoubleClick={(id) => this.props.onFolderDoubleClick(id)}
        onDetailsClick={(id) => this.props.onFolderDetailsClick(id)}
      />
    ));

    if (this.props.dropzone) {
      return (
        <Dropzone
          onDrop={this.props.onDrop}
          canDrop
          elements={folderElements}
        />
      );
    }

    return <>{folderElements}</>;
  }
}

export default FolderList;
