import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface QueueItem {
  idx: number;
  data: any;
}

interface InitialState {
  completed: QueueItem[];
  pending: QueueItem[];
  currentIdx: number;
  status: string;
  lastIdx: number;
}

const initialState: InitialState = {
  completed: [],
  pending: [],
  currentIdx: 0,
  status: 'idle',
  lastIdx: 0,
};

const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    enqueue(state, action) {
      for (let i = 0; i < action.payload.items.length; i++) {
        state.pending.push({
          data: action.payload.items[i],
          idx: state.lastIdx++,
        });
      }
    },

    dequeue(state) {
      if (state.lastIdx > 0) {
        state.lastIdx = state.lastIdx - 1;
        state.pending.pop();
      }
    },
  },
});

export const selectLast = (state: RootState) =>
  state.queue.pending[state.queue.lastIdx - 1];

export const { enqueue, dequeue } = queueSlice.actions;
export default queueSlice.reducer;
