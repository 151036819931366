import '../../../shared/styles/UtilityBar.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import { signOut } from '../../../app/store/authSlice';

import { RootState } from '../../../app/store/store';
import BackButton from './BackButton';
import ContextMenu from './ContextMenu';
import FolderPath from './FolderPath/FolderPath';
import { openOverlay } from '../../../app/store/overlaySlice';
import { OverlayType } from '../../../app/Modal';
import { fetchAll } from '../../../app/store/folderSlice';

const mapStateToProps = (state: RootState) => ({
  path: state.path.path,
  selected: state.active.selected,
});

const mapDispatchToProps = {
  fetchAll,
  openOverlay,
  signOut
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class TopActionBar extends React.Component<Props> {
  onLogoutClick() {
    this.props.signOut().unwrap().then((_: any) => {
      window.location.href = '/login';
    });
  }

  onBackArrowClick() {
    if (this.props.path.length === 0) {
      return;
    }
    const parentFolder = this.props.path[this.props.path.length - 2];
    const id = parentFolder ? parentFolder.id : null;
    this.props.fetchAll(id);
  }

  onFragmentClick(id: number | null) {
    this.props.fetchAll(id);
  }

  onAddClick() {
    this.props.openOverlay({
      overlayType: OverlayType.CREATE_MENU,
      overlayData: {},
    });
  }

  onTagSettingsClick() {
    this.props.openOverlay({
      overlayType: OverlayType.TAG_SETTINGS,
      overlayData: {},
    });
  }

  render() {
    return (
      <div className="utility-bar">
        <BackButton onClick={() => this.onBackArrowClick()} />

        <div className="utility-bar-path">
          <FolderPath
            onFragmentClick={(id) => this.onFragmentClick(id)}
            onPlusClick={() => this.onAddClick()}
            pathFragments={this.props.path}
          />
        </div>
        {/* <ContextMenu /> */}
        <div className="utility-bar-info">
          <div className="utility-bar-drive-data">
            <div className="utility-bar-data-element">
              <Icon icon={Icons.FOLDER} size={32} />
              <span className="utility-bar-data-text">2</span>
            </div>
            <div className="utility-bar-data-element">
              <Icon icon={Icons.FILE_TEXT} size={32} />
              <span className="utility-bar-data-text">13</span>
            </div>
            <div className="utility-bar-data-element">
              <Icon icon={Icons.UPLOAD} size={32} />
              <span className="utility-bar-data-text">12.7 MB</span>
            </div>
          </div>
        </div>
        <div className="utility-bar-actions">
          <div
            className="utility-bar-action-button"
            onClick={() => this.onTagSettingsClick()}
          >
            <Icon size={24} icon={Icons.TAG} />
            <span className="utility-bar-action-text">Tags</span>
          </div>
          <div
            className="utility-bar-action-button"
            onClick={() => this.onLogoutClick()}
          >
            <Icon size={24} icon={Icons.LOGOUT} />
            <span className="utility-bar-action-text">Logout</span>
          </div>
          {/* <div
            className="utility-bar-action-button"
            onClick={() => this.onFilterClick()}
          >
            <Icon size={28} icon={Icons.LAYERS} />
            <span className="utility-bar-action-text">Queue</span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default connector(TopActionBar);
