import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverlayType } from '../Modal';

export interface OverlayState {
  isOverlayOpened: boolean;
  isUploadQueueOpened: boolean;
  overlayType: OverlayType | null;
  overlayData: any;
}

const initialState: OverlayState = {
  isOverlayOpened: false,
  overlayType: null,
  overlayData: {},
  isUploadQueueOpened: false,
};

interface OpenPayload {
  overlayType: OverlayType;
  overlayData: any;
}

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    openOverlay: (state, action: PayloadAction<OpenPayload>) => {
      state.isOverlayOpened = true;
      state.overlayType = action.payload.overlayType;
      state.overlayData = action.payload.overlayData;
    },

    openUploadQueue: (state) => {
      state.isUploadQueueOpened = true;
    },

    closeUploadQueue: (state) => {
      state.isUploadQueueOpened = false;
    },

    closeOverlay: (state) => {
      state.isOverlayOpened = false;
      state.overlayType = null;
      state.overlayData = {};
    },
  },
});

export const { closeOverlay, openOverlay, closeUploadQueue, openUploadQueue } =
  overlaySlice.actions;

export default overlaySlice.reducer;
