import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import FileAdapter from '../../shared/adapters/file';
import FolderAdapter from '../../shared/adapters/folder';
import { Status } from './activeSlice';
import { RootState } from './store';

interface Folder {
  id: number;
  name: string;
  description: string | null;
  owner: string;
  parentFolderId: null | number;
  device: string;
  createdAt: string;
  userId: number;
}

enum FolderAction {
  CREATE = 'folders/create',
  UPDATE = 'folders/update',
  DELETE = 'folders/delete',
  FETCH_SINGLE = 'folders/fetchSingle',
  FETCH_ALL = 'folders/fetchAll',
  UPDATE_PARENT = 'folders/update/parent',
}

const foldersAdapter = createEntityAdapter<Folder>({
  selectId: (folder) => folder.id,
});

interface AdditionalState {
  currentFolderId: null | number;
  status: Status;
}

const initialState = foldersAdapter.getInitialState<AdditionalState>({
  currentFolderId: null,
  status: Status.IDLE,
});

export const fetchAll = createAsyncThunk(
  FolderAction.FETCH_ALL,
  async (id: any, { dispatch }) => {
    const folderId = id === null ? '' : id;
    const [files, folders] = await Promise.all([
      FileAdapter.fetchByFolder(folderId),
      FolderAdapter.fetchFolders(folderId),
    ]);

    return {
      files,
      folders: folders.folders,
      path: folders.folderPath,
      id,
    };
  }
);

export const createFolder = createAsyncThunk(
  FolderAction.CREATE,
  async (folderBody: any) => {
    const folder = await FolderAdapter.create(folderBody);

    return folder;
  }
);

export const updateFolder = createAsyncThunk(
  FolderAction.UPDATE,
  async (data: any) => {
    return await FolderAdapter.update(data.id, data.body);
  }
);

export const deleteFolder = createAsyncThunk(
  FolderAction.DELETE,
  async (data: any) => {
    await FolderAdapter.delete(data.id);

    return {
      id: data.id,
    };
  }
);

export const fetchFolder = createAsyncThunk(
  FolderAction.FETCH_SINGLE,
  async (id: number) => {
    return await FolderAdapter.fetch(id);
  }
);

export const updateFolderParent = createAsyncThunk(
  FolderAction.UPDATE_PARENT,
  async (data: any) => {
    return await FolderAdapter.move(data.id, {
      parentFolderId: data.parentFolderId,
    });
  }
);

const folderSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.currentFolderId = action.payload.id;
      foldersAdapter.setAll(state, action.payload.folders);
    });
    builder.addCase(createFolder.fulfilled, (state, action) => {
      if (state.currentFolderId === action.payload.parentFolderId) {
        foldersAdapter.addOne(state, action.payload);
      }
    });

    builder.addCase(updateFolderParent.fulfilled, (state, action) => {
      if (action.payload.parentFolderId !== state.currentFolderId) {
        foldersAdapter.removeOne(state, action.payload.id);
      } else {
        foldersAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            parentFolderId: action.payload.parentFolderId,
          },
        });
      }
    });

    builder.addCase(updateFolder.fulfilled, (state, action) => {
      if (action.payload.parentFolderId !== state.currentFolderId) {
        foldersAdapter.removeOne(state, action.payload.id);
      } else {
        foldersAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            name: action.payload.name,
          },
        });
      }
    });

    builder.addCase(deleteFolder.fulfilled, (state, action) => {
      if (state.currentFolderId === action.payload.id) {
        state.currentFolderId = null;
      } else {
        foldersAdapter.removeOne(state, action.payload.id);
      }
    });
  },
});

export const folderSelectors = foldersAdapter.getSelectors(
  (state: RootState) => state.folders
);

export default folderSlice.reducer;
