import Icon, { Icons } from '../Icon/Icon';
import './styles.css';

interface AddMoreProps {
  onClick: (e: any) => void;
}

const AddMore: React.FC<AddMoreProps> = ({ onClick }) => {
  return (
    <div className="add-more" onClick={(e) => onClick(e)}>
      <Icon icon={Icons.PLUS} size={16} />
      <span>Add more</span>
    </div>
  );
};

export default AddMore;
