import '../../styles/Input.css';

import React from 'react';
import Icon, { Icons } from '../Icon/Icon';

interface OwnProps {
  placeholder?: string;
  value: string | null;
  setValue: (value: string, name: string) => any;
  className?: string;
  type: string;
  name: string;
  icon?: Icons;
}

type Props = OwnProps;

class Input extends React.Component<Props> {
  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.setValue(e.target.value, e.target.name);
  }

  render() {
    return (
      <div className="input-wrapper">
        {this.props.icon && (
          <span className="input-icon">
            <Icon icon={this.props.icon!} size={24} />
          </span>
        )}
        <input
          style={{
            padding: this.props.icon ? '0 7px 0 46px' : '0 18px 0 18px',
          }}
          name={this.props.name}
          className={`input ${this.props.className || ''}`}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value || ''}
          onChange={(e) => this.onChange(e)}
        />
      </div>
    );
  }
}

export default Input;
