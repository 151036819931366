import { configureStore } from '@reduxjs/toolkit';

import activeReducer from './activeSlice';
import overlayReducer from './overlaySlice';
import tagReducer from './tagSlice';
import fileReducer from './fileSlice';
import folderReducer from './folderSlice';
import pathReducer from './pathSlice';
import queueReducer from './queueSlice';
import authReducer from './authSlice';

export const store = configureStore({
  reducer: {
    overlay: overlayReducer,
    folders: folderReducer,
    files: fileReducer,
    active: activeReducer,
    tags: tagReducer,
    path: pathReducer,
    queue: queueReducer,
    auth: authReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
