export const formatName = (
  name: string,
  suffix: string,
  previewSavedName: string | null
) => {
  const hasSuffix = name.endsWith(`.${suffix}`);
  const hasPreview = previewSavedName !== null;

  const suffixlessName = hasSuffix ? name.slice(0, -suffix.length - 1) : name;

  const downloadName = hasPreview
    ? suffixlessName + '.pdf'
    : suffixlessName + '.' + suffix;

  return downloadName;
};
