import '../../styles/Button.css';

import React from 'react';
import Spinner from '../Spinner';

interface OwnProps {
  text: string;
  onClick: (e: any) => any;
  primary?: boolean;
  className?: string;
  isWorking?: boolean;
}

type Props = OwnProps;

class Button extends React.Component<Props> {
  onClick(e: any) {
    e.preventDefault();
    e.stopPropagation();

    return this.props.onClick(e);
  }

  render() {
    const className = `button ${this.props.primary ? 'button-primary' : ''} ${
      this.props.className || ''
    }`;
    return (
      <button
        onClick={(e) => this.onClick(e)}
        type="button"
        className={className}
        style={{
          opacity: this.props.isWorking ? 0.6 : 1,
        }}
      >
        {this.props.isWorking && <Spinner size={24} color="white" />}
        {this.props.text}
      </button>
    );
  }
}

export default Button;
