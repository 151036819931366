import Icon, { Icons } from '../../../../shared/components/Icon/Icon';

interface FileProps {
  id: number;
  name: string;
  onClick: (id: number) => void;
}

const File: React.FC<FileProps> = ({ id, name, onClick }) => {
  return (
    <div className="file" onClick={() => onClick(id)}>
      <span className="file-icon">
        <Icon icon={Icons.FILE_TEXT} size={26}></Icon>
      </span>
      <span> {name}</span>
    </div>
  );
};

export default File;
