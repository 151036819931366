import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import FileAdapter from '../../shared/adapters/file';
import { fetchAll } from './folderSlice';
import { RootState } from './store';

interface File {
  id: number;
  name: string;
  description: string | null;
  owner: string;
  folderId: null | number;
  device: string;
  createdAt: string;
  userId: number;
}

enum FileAction {
  CREATE = 'files/create',
  UPDATE = 'files/update',
  DELETE = 'files/delete',
  FETCH_SINGLE = 'files/fetchSingle',
  UPDATE_PARENT = 'files/update/parent',
}

const filesAdapter = createEntityAdapter<File>({
  selectId: (file) => file.id,
});

const initialState = filesAdapter.getInitialState();

export const fetchFile = createAsyncThunk(
  FileAction.FETCH_SINGLE,
  async (id: number) => {
    return await FileAdapter.fetch(id);
  }
);

export const updateFile = createAsyncThunk(
  FileAction.UPDATE,
  async (data: any) => {
    return await FileAdapter.update(data.id, data.body);
  }
);

export const updateFileParent = createAsyncThunk(
  FileAction.UPDATE_PARENT,
  async (data: any) => {
    return await FileAdapter.move(data.id, {
      parentFolderId: data.parentFolderId,
    });
  }
);

export const deleteFile = createAsyncThunk(
  FileAction.DELETE,
  async (data: any) => {
    await FileAdapter.delete(data.id);

    return data.id;
  }
);

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    addUploadedFile(state, action) {
      filesAdapter.addOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      filesAdapter.setAll(state, action.payload.files);
    });

    builder.addCase(updateFileParent.fulfilled, (state, action) => {
      filesAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          folderId: action.payload.folderId,
        },
      });
    });

    builder.addCase(updateFile.fulfilled, filesAdapter.updateOne);

    builder.addCase(deleteFile.fulfilled, filesAdapter.removeOne);
  },
});

export const { addUploadedFile } = fileSlice.actions;

export const fileSelectors = filesAdapter.getSelectors(
  (state: RootState) => state.files
);

export default fileSlice.reducer;
