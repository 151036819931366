import '../../../shared/styles/UpdateGrant.css';

import React, { ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeOverlay, openOverlay } from '../../../app/store/overlaySlice';
import { RootState } from '../../../app/store/store';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import Input from '../../../shared/components/Input/Index';
import OverlayBody from '../../../shared/components/OverlayBody';
import OverlayHeader from '../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../shared/components/OverlayWrapper';
import OverlayFooter from '../../../shared/components/OverlayFooter';
import Button from '../../../shared/components/Button/Button';
import toast from 'react-hot-toast';
import { Status, updateGrant } from '../../../app/store/activeSlice';

export enum GrantStatus {
  LOCKED = 2,
  LIMITED = 1,
  GLOBAL = 0,
}

const mapStateToProps = (state: RootState) => ({
  selected: state.active.selected,
  type: state.active.type,
  grant: state.active.selected.grant,
  status: state.active.status,
});

const mapDispatchToProps = {
  closeOverlay,
  openOverlay,
  updateGrant,
};

interface OwnProps {
  initialType?: GrantStatus;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

interface State {
  type: GrantStatus | null;
  hasCopied: boolean;
}

class UpdateGrant extends React.Component<Props, State> {
  private grantOptions = {
    [GrantStatus.GLOBAL]: {
      text: 'Kdor koli s povezavo',
      subtext: 'Vsi na internetu s to povezavo imajo dostop',
    },
    // [GrantStatus.LIMITED]: {
    //   text: 'Omejen dostop',
    //   subtext: 'Samo izbrane osebe imajo dostop',
    // },
    [GrantStatus.LOCKED]: {
      text: 'Zaklenjeno',
      subtext: 'Samo ti imaš dostop',
    },
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hasCopied: false,
      type: this.getGrantStatus(this.props.initialType),
    };
  }

  componentDidUpdate() {
    if (this.state.hasCopied === true) {
      setTimeout(() => {
        this.setState({
          hasCopied: false,
        });
      }, 5000);
    }
  }

  getGrantStatus(initialType?: GrantStatus) {
    if (initialType) {
      return initialType;
    }

    const { members, restricted } = this.props.grant;

    if (members.length === 0 && restricted === 1) {
      return GrantStatus.LOCKED;
    }

    if (members.length > 0 && members.restricted === 1) {
      return GrantStatus.LIMITED;
    }

    if (restricted === 0) {
      return GrantStatus.GLOBAL;
    }

    return null;
  }

  onSelect(e: ChangeEvent<HTMLSelectElement>) {
    const type = parseInt(e.target.value) as GrantStatus;

    this.setState({
      type,
    });
  }

  onCloseClick() {
    this.props.closeOverlay();
  }

  onSaveClick() {
    const { accessKey } = this.props.grant;

    let restricted = 1;

    if (this.state.type === GrantStatus.GLOBAL) {
      restricted = 0;
    }

    this.props
      .updateGrant({
        refreshAccessKey: false,
        restricted,
        accessKey,
      })
      .unwrap()
      .then(() => {
        this.props.closeOverlay();

        toast.success('Skupna raba posoboljena');
      });
  }

  copyToClipboard(
    url: string,
    options: {
      notify: boolean;
      toggle: boolean;
    }
  ) {
    if (this.state.hasCopied === true) return false;

    navigator.clipboard.writeText(url).then(() => {
      if (options.notify) {
        toast.success('Povezava uspešno kopirana');
      }

      if (options.toggle) {
        this.setState({
          hasCopied: true,
        });
      }
    });
  }

  onNewUrlClick() {
    const { accessKey, restricted } = this.props.grant;

    this.props
      .updateGrant({
        accessKey,
        restricted,
        members: undefined,
        refreshAccessKey: true,
      })
      .unwrap()
      .then(() => {
        toast.success('Povezava kreirana in kopirana v odložišče');

        const url = `https://drive.purplebear.io/share/${this.props.grant.accessKey}`;

        this.copyToClipboard(url, {
          notify: false,
          toggle: false,
        });
      });
  }

  render() {
    if (this.state.type === null) {
      return null;
    }

    const url = `https://drive.purplebear.io/share/${this.props.grant.accessKey}`;

    if (this.state.type === GrantStatus.LIMITED) {
      return;
    }

    const subtext = this.grantOptions[this.state.type].subtext;

    const { type, hasCopied } = this.state;

    const isWorking = this.props.status === Status.LOADING;

    return (
      <OverlayWrapper className="">
        <OverlayHeader
          showDivider={true}
          icon={Icons.SHARE2}
          title="Uredi skupno rabo"
          subtitle={this.props.selected.name}
        />

        <OverlayBody>
          <div className="link">
            <div
              className="icon-wrapper"
              onClick={() =>
                this.copyToClipboard(url, {
                  notify: true,
                  toggle: true,
                })
              }
            >
              {hasCopied ? (
                <Icon icon={Icons.CHECK} size={28} />
              ) : (
                <Icon icon={Icons.CLIPBOARD} size={28} />
              )}
            </div>
            <div className="link-wrapper">
              <Input
                name="url"
                type="text"
                className="full"
                value={url}
                setValue={() => {}}
              />
            </div>
          </div>

          <div className="share">
            <div className="icon-bg">
              {type === GrantStatus.GLOBAL && (
                <Icon icon={Icons.GLOBE} size={22} />
              )}
              {/* {type === GrantStatus.LIMITED && (
                <Icon icon={Icons.USERS} size={22} />
              )} */}
              {type === GrantStatus.LOCKED && (
                <Icon icon={Icons.LOCK} size={22} />
              )}
            </div>
            <div className="share-type-wrapper">
              <div className="share-description">
                <select value={type} onChange={(e) => this.onSelect(e)}>
                  {/* <option value={GrantStatus.LIMITED}>
                    {this.grantOptions[GrantStatus.LIMITED].text}
                  </option> */}
                  <option value={GrantStatus.GLOBAL}>
                    {this.grantOptions[GrantStatus.GLOBAL].text}
                  </option>
                  <option value={GrantStatus.LOCKED}>
                    {this.grantOptions[GrantStatus.LOCKED].text}
                  </option>
                </select>
              </div>
              <div className="share-subtext">{subtext}</div>
            </div>
            <div
              className={`switch-type ${
                type === GrantStatus.GLOBAL || type === GrantStatus.LOCKED
                  ? 'disabled'
                  : ''
              }`}
              onClick={() => {}}
            >
              <Icon icon={Icons.CORNER_DOWN_LEFT} size={24} />
            </div>
          </div>
        </OverlayBody>
        <OverlayFooter>
          <div className="copy-link" onClick={() => this.onNewUrlClick()}>
            <Icon icon={Icons.REFRESH_CW} size={20}></Icon>
            <span>Nova povezava</span>
          </div>
          <Button text="Zapri" onClick={() => this.onCloseClick()} />
          <Button
            text="Shrani"
            isWorking={isWorking}
            onClick={() => this.onSaveClick()}
            primary
          />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(UpdateGrant);
