import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import React, { useContext } from 'react';
import FileExplorerContext from '../FileExplorerContext';
interface DriveRootProps {
  name: string;
  id: number;
  isOpened: boolean;
  onClick: (id: number) => void;
}

const DriveRoot: React.FC<DriveRootProps> = ({
  name,
  id,
  isOpened,
  onClick,
}) => {
  const { clearOpenedFolders } = useContext(FileExplorerContext);

  return (
    <div className="folder-name">
      <span className="folder-icon">
        <Icon icon={Icons.HARD_DRIVE} size={26}></Icon>
      </span>
      <span onClick={() => onClick(id)}>{name}</span>
      <div className="context-tools">
        <span>
          <Icon icon={Icons.FILE_PLUS} size={22}></Icon>
        </span>
        <span>
          <Icon icon={Icons.FOLDER_PLUS} size={22}></Icon>
        </span>
        <span onClick={clearOpenedFolders}>
          <Icon icon={Icons.MINIMIZE_2} size={22}></Icon>
        </span>
      </div>
    </div>
  );
};

export default DriveRoot;
