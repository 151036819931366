import '../../../shared/styles/Details.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ActionMenu from './ActionMenu';
import { RootState } from '../../../app/store/store';

import { DataType } from '../../../app/store/activeSlice';

import Header from './Header';
import InfoTable from './InfoTable';
import TagBar from './TagBar';
import { fetchFile } from '../../../app/store/fileSlice';
import { fetchFolder } from '../../../app/store/folderSlice';
import { BASE_URL } from '../../../shared/constants';

const mapStateToProps = (state: RootState) => ({
  selected: state.active.selected,
  type: state.active.type,
  id: state.active.id,
  status: state.active.status,
});

const mapDispatchToProps = {
  fetchFile,
  fetchFolder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class DetailsBar extends React.Component<Props> {
  refreshData() {
    console.log(this.props.id);
    if (this.props.id === null) {
      return null;
    }

    const { id, type } = this.props;

    if (type === DataType.FILE) {
      return this.props.fetchFile(id);
    }

    if (type === DataType.FOLDER) {
      return this.props.fetchFolder(id);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.id === null) {
      return null;
    }

    if (prevProps.id !== this.props.id || prevProps.type !== this.props.type) {
      this.refreshData();
    }
  }

  generateDownloadName() {
    const { selected, type } = this.props;
    if (type === DataType.FILE) {
      if (selected.name.includes(`.${selected.suffix}`)) {
        return selected.name;
      }

      return `${selected.name}.${selected.suffix}`;
    }

    if (type === DataType.FOLDER) {
      return `${selected.name}.zip`;
    }

    return '';
  }

  render() {
    const { selected, type } = this.props;

    // if (this.props.status === Status.LOADING) {
    //   return (
    //     <div className="details-wrapper">
    //       <div className="details-loader">
    //         <BounceLoader color="#4957fa" loading={true} size={120} />
    //       </div>
    //     </div>
    //   );
    // }

    if (selected === null) {
      return null;
    }

    const pathFragment = type === DataType.FILE ? 'file' : 'folder';

    const url = `${BASE_URL}/dl/${pathFragment}/${selected.id}/attachment`;

    return (
      <div className="details-wrapper">
        <Header title={selected.name} type={type!} />
        <TagBar tags={selected.tags} />
        <InfoTable data={selected} type={type!} />
        <ActionMenu id={selected.id} name={this.generateDownloadName()} />
      </div>
    );
  }
}

export default connector(DetailsBar);
