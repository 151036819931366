import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import { formatBytes } from '../../../../shared/utils/formatBytes';

interface FileListElementProps {
  file: any;
  onDoubleClick: (id: number) => void;
  onClick: (id: number) => void;
  onDetailsClick: (id: number) => void;
}

const FileListElement: React.FC<FileListElementProps> = ({
  onDoubleClick,
  onClick,
  onDetailsClick,
  file,
}) => {
  return (
    <div
      className={'folder-list-element'}
      onClick={() => onClick(file.id)}
      onDoubleClick={() => onDoubleClick(file.id)}
    >
      <div className="element-icon">
        <Icon icon={Icons.FILE_TEXT} size={36} />
      </div>

      <div className="element-name">{file.name}</div>

      <div className="element-type">{file.suffix}</div>

      <div className="element-owner">Nejc Mikelj</div>

      <div className="element-date">
        {new Date(file.createdAt).toLocaleDateString('sl')}
      </div>

      <div className="element-size">{formatBytes(file.size)}</div>

      <div
        className="element-details-button"
        onClick={() => onDetailsClick(file.id)}
      >
        <Icon icon={Icons.CHEVRONS_RIGHT} size={36} />
      </div>
    </div>
  );
};

export default FileListElement;
