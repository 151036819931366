import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  AnyAction,
} from '@reduxjs/toolkit';
import GrantAdapter from '../../shared/adapters/grant';
import {
  deleteFile,
  fetchFile,
  updateFile,
  updateFileParent,
} from './fileSlice';
import {
  createFolder,
  deleteFolder,
  fetchFolder,
  updateFolder,
  updateFolderParent,
} from './folderSlice';

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum DataType {
  FILE = 'file',
  FOLDER = 'folder',
}

interface ActiveState {
  selected: any;
  type: DataType | null;
  status: Status | null;
  error: null | string;
  id: number | null;
}

const initialState: ActiveState = {
  selected: null,
  id: null,
  type: null,
  status: Status.IDLE,
  error: null,
};

export const updateGrant = createAsyncThunk(
  'active/selected/updateGrant',
  async (body: any) => {
    const grant = await GrantAdapter.update(body);

    return grant;
  }
);

const isPendingAction = (action: AnyAction) => action.type.endsWith('/pending');

const isFulfilledAction = (action: AnyAction) =>
  action.type.endsWith('/fulfilled');

const isRejectedAction = (action: AnyAction) =>
  action.type.endsWith('/rejected');

interface OpenDetailsPayload {
  id: number;
  type: DataType;
}

const detailsSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    setActive: (state, action: PayloadAction<OpenDetailsPayload>) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
    },

    clearActive: (state) => {
      state = initialState;
    },
  },

  extraReducers(builder) {
    builder.addCase(createFolder.fulfilled, (state, action) => {
      // state.id = action.payload.id;
      // state.type = DataType.FOLDER;
    });

    builder.addCase(deleteFile.fulfilled, (state) => {
      state.id = null;
      state.selected = null;
      state.type = null;
    });

    builder.addCase(deleteFolder.fulfilled, (state) => {
      state.id = null;
      state.selected = null;
      state.type = null;
    });

    builder.addCase(updateFolder.fulfilled, (state, action) => {
      if (state.id === action.payload.id) {
        state.selected = {
          ...action.payload,
          grant: state.selected.grant,
        };
      }
    });

    builder.addCase(updateFolderParent.fulfilled, (state, action) => {
      state.selected = {
        ...state.selected,
        parentFolderId: action.payload.parentFolderId,
        parentName: action.payload.parentName,
        grant: state.selected.grant,
      };
    });

    builder.addCase(updateFileParent.fulfilled, (state, action) => {
      state.selected = {
        ...state.selected,
        folderId: action.payload.folderId,
        parentName: action.payload.parentName,
        grant: state.selected.grant,
      };
    });

    builder.addCase(updateFile.fulfilled, (state, action) => {
      state.selected = {
        ...action.payload,
        grant: state.selected.grant,
      };
    });

    builder.addCase(updateGrant.fulfilled, (state, action) => {
      state.selected.grant = action.payload;
    });

    builder.addCase(fetchFile.fulfilled, (state, action) => {
      state.selected = action.payload;
      state.type = DataType.FILE;
    });

    builder.addCase(fetchFolder.fulfilled, (state, action) => {
      state.selected = action.payload;
      state.type = DataType.FOLDER;
    });

    builder.addMatcher(isPendingAction, (state) => {
      state.status = Status.LOADING;
    });

    builder.addMatcher(isFulfilledAction, (state) => {
      state.status = Status.SUCCEEDED;
    });

    builder.addMatcher(isRejectedAction, (state) => {
      state.status = Status.FAILED;
    });
  },
});

export const { setActive, clearActive } = detailsSlice.actions;

export default detailsSlice.reducer;
