import client from '../../app/axiosClient';

class FileAdapter {
  static async uploadFile(body: any, onProgress: (progress: number) => void) {
    const formData = new FormData();

    const parentFolderId =
      body.parentFolderId !== null ? body.parentFolderId.toString() : 'null';

    formData.append('name', body.name);
    formData.append('folderId', parentFolderId);
    formData.append('description', 'null');
    formData.append('file', body.file);

    const r = await client.post('/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        onProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    });

    return r.data;
  }

  static async fetchByFolder(id: string) {
    const files = await client.get('/file/' + id);

    return files.data;
  }

  static async downloadFile(url: string) {
    if (!url) {
      return null;
    }

    const response = await client.get(url, {
      responseType: 'blob',
      withCredentials: false,
    });

    return response.data;
  }

  static async fetch(id: number) {
    const file = await client.get('/file/single/' + id);

    return file.data;
  }

  static async update(id: number, body: any) {
    const file = await client.put('/file/' + id, body);

    return file.data;
  }

  static async move(id: number, body: any) {
    const file = await client.put('/file/move/' + id, body);

    return file.data;
  }

  static async delete(id: number) {
    await client.delete('/file/' + id);
  }
}

export default FileAdapter;
