import React from 'react';

export interface ContentCache {
  [key: string]: IFolderContents;
}

export interface OpenedFolderStore {
  [key: string]: boolean;
}

interface IFileExplorerContext {
  active: any | null;
  cache: ContentCache; // id
  openedFolders: OpenedFolderStore; // id
  clearOpenedFolders: () => void;
}

interface IFolderContents {
  files: any[];
  folders: any[];
}

const initialContext: IFileExplorerContext = {
  active: null,
  cache: {},
  openedFolders: {},
  clearOpenedFolders: () => {},
};

const FileExplorerContext =
  React.createContext<IFileExplorerContext>(initialContext);

export const FileExplorerProvider = FileExplorerContext.Provider;

export default FileExplorerContext;
