import { useEffect, useState } from 'react';

const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  const downHandler = ({ key }: { key: string }) => {
    if (key === targetKey) setKeyPressed(true);
  };

  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) setKeyPressed(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', downHandler, false);
    document.addEventListener('keyup', upHandler, false);

    return () => {
      document.removeEventListener('keydown', downHandler);
      document.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export default useKeyPress;
