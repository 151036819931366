import { useState } from 'react';
import {
  DataType,
  setActive as setActiveFile,
} from '../../../app/store/activeSlice';
import { fetchAll } from '../../../app/store/folderSlice';
import FileAdapter from '../../../shared/adapters/file';
import FolderAdapter from '../../../shared/adapters/folder';
import { Icons } from '../../../shared/components/Icon/Icon';
import Input from '../../../shared/components/Input/Index';
import { useAppDispatch } from '../../../shared/hooks/useAppDispatch';
import {
  ContentCache,
  FileExplorerProvider,
  OpenedFolderStore,
} from './FileExplorerContext';
import Folder from './Folder';
import './styles.css';

const FileExplorer = () => {
  const [active, setActive] = useState<any | null>(null);
  const [cache, updateCache] = useState<ContentCache>({});
  const [opened, updateOpened] = useState<OpenedFolderStore>({});
  const [query, setQuery] = useState<string>('');
  const dispatch = useAppDispatch();

  const onFolderClick = async (id: any) => {
    setActive(id);

    dispatch(fetchAll(id));

    if (!cache[id]) {
      const [{ folders }, files] = await Promise.all([
        FolderAdapter.fetchFolders(id || ''),
        FileAdapter.fetchByFolder(id || ''),
      ]);

      updateCache((c) => ({
        ...c,
        [id]: {
          files,
          folders,
        },
      }));
    }

    updateOpened((opened) => {
      if (opened[id]) {
        return {
          ...opened,
          [id]: false,
        };
      }

      return {
        ...opened,
        [id]: true,
      };
    });
  };

  const onFileClick = (id: any) => {
    setActive(id);
    console.log(id);

    dispatch(
      setActiveFile({
        type: DataType.FILE,
        id,
      })
    );
  };

  const clearOpenedFolders = () => {
    updateOpened({});
  };

  return (
    <FileExplorerProvider
      value={{
        active,
        cache,
        openedFolders: opened,
        clearOpenedFolders,
      }}
    >
      <div className="file-explorer">
        <div className="search">
          <Input
            name="query"
            setValue={(value) => setQuery(value)}
            value={query}
            type="text"
            icon={Icons.FILTER}
            placeholder="Click to show filters"
            className="filter-field"
          ></Input>
        </div>
        <Folder
          contents={cache[0]}
          onFileClick={(id) => onFileClick(id)}
          onClick={(id) => onFolderClick(id)}
          name="Moj disk"
          id={0}
        ></Folder>
      </div>
    </FileExplorerProvider>
  );
};

export default FileExplorer;
