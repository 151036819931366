import React from 'react';
import Tag from './Tag';

interface OwnProps {
  tags: any[];
}

type Props = OwnProps;

class TagBar extends React.Component<Props> {
  render() {
    const defaultTag = {
      color: '#4957fa',
      name: 'Neoznačeno',
    };

    const tags = this.props.tags.length > 0 ? this.props.tags : [defaultTag];

    const elements = tags.map((tag, i) => (
      <Tag key={i} color={'#2c3e50'} name={tag.name} index={i} />
    ));

    return (
      <div className="tag-bar">
        <div className="tag-wrapper">{elements}</div>
      </div>
    );
  }
}

export default TagBar;
