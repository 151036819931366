import React from 'react';
import DataList from './DataList';
import DetailsBar from './DetailsBar';
import TopActionBar from './TopActionBar';
import { HomePage } from './styles';
import { fetchAllTags } from '../../app/store/tagSlice';
import { connect, ConnectedProps } from 'react-redux';
import UploadQueue from './UploadQueue';
import FileExplorer from './FileExplorer';
import FilePreview from './DataList/FilePreview';
import { RootState } from '../../app/store/store';

const mapDispatchToProps = {
  fetchAllTags,
};

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class Home extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchAllTags();
  }

  render() {
    return (
      <HomePage>
        <TopActionBar />
        <UploadQueue />
        <div className="content">
          <FileExplorer></FileExplorer>
          <DataList></DataList>

          <DetailsBar />
        </div>
      </HomePage>
    );
  }
}

export default connector(Home);
