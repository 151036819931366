import { useRef, useState } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import AddMore from './AddMore';

interface SelectProps {
  onChange: (values: any[]) => void;
  options: any[];
  values: any[];
  defaultValue: string;
  renderValue: ({
    value,
    removeOptionValue,
  }: {
    value: string;
    removeOptionValue: () => void;
  }) => any;
  renderOption: (option: any) => any;
}

const Select: React.FC<SelectProps> = ({
  onChange,
  values,
  options,
  renderValue,
  renderOption,
  defaultValue,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const [_searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef(null);

  const activateDropdown = () => {
    setDropdownOpen(true);
  };

  const handleChange = (value: any | any[]) => {
    if (Array.isArray(value)) {
      onChange(value);
    } else {
      onChange([...values, value]);
    }
  };

  const removeOptionValue = (optionValue: any) => {
    handleChange(values.filter((value) => value !== optionValue));
  };

  const selectOptionValue = (optionValue: any) => {
    deactiveDropdown();
    handleChange(optionValue);
  };

  const deactiveDropdown = () => {
    setDropdownOpen(false);
    // setSearchValue('');
  };

  const filteredOptions = options.filter(
    (option) => !values.includes(option.id)
  );

  useClickOutside(dropdownRef, deactiveDropdown);

  return (
    <div className="multi-select" ref={dropdownRef}>
      {values.map((value) => {
        return renderValue({
          value,
          removeOptionValue: () => {
            removeOptionValue(value);
            activateDropdown();
          },
        });
      })}

      {values.length === 0 && (
        <div onClick={() => activateDropdown()} className="select-placeholder">
          {defaultValue}
        </div>
      )}

      {values.length > 0 && <AddMore onClick={() => activateDropdown()} />}
      {isDropdownOpen && (
        <div className="dropdown">
          {filteredOptions.map((option) => {
            return (
              <div
                className="dropdown-option"
                onClick={() => selectOptionValue(option.id)}
              >
                {renderOption(option)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Select;
