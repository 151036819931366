import '../shared/styles/App.css';

import { Fragment } from 'react';
import Overlay from './Modal';
import Routes from './routes';
import Toast from './Toast';

import './fontStyles.css';

const App = () => (
  <Fragment>
    {/* <Offline></Offline> */}
    <Routes />
    <Overlay />
    <Toast />
  </Fragment>
);

export default App;
