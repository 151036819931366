import Icon, { Icons } from '../Icon/Icon';
import './styles.css';

interface ValueProps {
  onRemove: () => void;
  icon?: Icons;
  value: string;
}

const Value: React.FC<ValueProps> = ({ onRemove, icon, value }) => {
  return (
    <span className="selected-option">
      {icon && <Icon icon={icon} strokeWidth={3} size={18} />}
      {value}
      <span onClick={() => onRemove()}>
        {' '}
        <Icon icon={Icons.X} size={18} />
      </span>
    </span>
  );
};

export default Value;
