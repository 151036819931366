import '../../../../shared/styles/DataMove.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DataType } from '../../../../app/store/activeSlice';
import { updateFileParent } from '../../../../app/store/fileSlice';
import {
  fetchAll,
  updateFolderParent,
} from '../../../../app/store/folderSlice';

import { closeOverlay } from '../../../../app/store/overlaySlice';
import { RootState } from '../../../../app/store/store';
import Button from '../../../../shared/components/Button/Button';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import OverlayBody from '../../../../shared/components/OverlayBody';
import OverlayFooter from '../../../../shared/components/OverlayFooter';
import OverlayHeader from '../../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../../shared/components/OverlayWrapper';
import FolderAdapter from '../../../../shared/adapters/folder';

const mapStateToProps = (state: RootState) => ({
  selected: state.active.selected,
  type: state.active.type,
});

const mapDispatchToProps = {
  closeOverlay,
  updateFileParent,
  updateFolderParent,
  fetchAll,
};

interface State {
  folders: any[];
  selected: any;
  path: any;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class DataMove extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      folders: [],
      path: null,
      selected: {
        parentFolderId: null,
        name: 'Moj disk',
        id: null,
      },
    };
  }

  fetchFolders(id: number | null) {
    const folderId = id === null ? '' : id;

    FolderAdapter.fetchFolders(folderId).then((data) =>
      this.setState({
        folders: data.folders,
        path: data.folderPath,
      })
    );
  }

  componentDidMount() {
    this.fetchFolders(null);
  }

  onBackClick() {
    const path = this.state.path;

    const previousFolder = path[1];

    if (!previousFolder) {
      return this.fetchFolders(null);
    }

    const id = previousFolder ? previousFolder.id : null;
    this.fetchFolders(id);
  }

  getSelectedFolderName() {
    const selectedFolder = this.state.selected;

    return selectedFolder ? selectedFolder.name : 'Moj disk';
  }

  onHomeClick() {
    this.setState({
      selected: {
        parentFolderId: null,
        name: 'Moj disk',
        id: null,
      },
    });
    this.fetchFolders(null);
  }

  onCloseClick() {
    this.props.closeOverlay();
  }

  onFolderDoubleClick(id: number | null) {
    if (id === this.props.selected.id) {
      return;
    }

    this.fetchFolders(id);
  }

  onFolderClick(id: number | null) {
    if (id === this.props.selected.id) {
      return;
    }

    const folder = this.state.folders.find((folder) => folder.id === id);
    console.log(folder);
    this.setState({
      selected: folder,
    });
  }

  onSaveClick() {
    const payload = {
      id: this.props.selected.id,
      parentFolderId: this.state.selected.id,
    };

    const promise =
      this.props.type === DataType.FILE
        ? this.props.updateFileParent(payload)
        : this.props.updateFolderParent(payload);

    promise.unwrap().then((_) => {
      this.props.closeOverlay();
      this.props.fetchAll(this.state.selected.id);
    });
  }

  render() {
    const { type, selected } = this.props;
    const title = `Premakni ${type === DataType.FILE ? 'datoteko' : 'mapo'}`;

    return (
      <OverlayWrapper className="">
        <OverlayHeader
          icon={Icons.MOVE}
          title={title}
          subtitle={selected.name}
          showDivider
        ></OverlayHeader>
        <OverlayBody>
          <div className="home-folder" onClick={() => this.onHomeClick()}>
            Moj disk
          </div>
          <div className="folder-list">
            {this.state.folders.map((folder) => {
              const isSelected = this.state.selected.id === folder.id;
              const isCurrent = this.props.selected.id === folder.id;

              return (
                <div
                  onClick={() => this.onFolderClick(folder.id)}
                  onDoubleClick={() => this.onFolderDoubleClick(folder.id)}
                  className="folder-element"
                  style={{
                    background: isSelected ? '#4957fa34' : 'initial',
                    opacity: isCurrent ? 0.4 : 1,
                    cursor: isCurrent ? 'not-allowed' : 'pointer',
                  }}
                >
                  <Icon size={28} icon={Icons.FOLDER} />
                  <span
                    style={{
                      color: isSelected ? '#4957fa' : 'initial',
                    }}
                  >
                    {folder.name}
                  </span>
                </div>
              );
            })}
          </div>
        </OverlayBody>
        <OverlayFooter>
          <div className="left-menu">
            <div className="navigate-button" onClick={() => this.onBackClick()}>
              <Icon icon={Icons.CHEVRON_LEFT} size={28} />
            </div>
            <div className="selected-folder">
              {this.getSelectedFolderName()}
            </div>
          </div>
          <Button text="Zapri" onClick={() => this.onCloseClick()} />
          <Button text="Shrani" onClick={() => this.onSaveClick()} primary />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(DataMove);
