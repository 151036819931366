import './styles.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../app/store/store';
import {
  createTag,
  deleteTag,
  tagSelectors,
} from '../../../app/store/tagSlice';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import OverlayBody from '../../../shared/components/OverlayBody';
import OverlayHeader from '../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../shared/components/OverlayWrapper';
import OverlayFooter from '../../../shared/components/OverlayFooter';
import Button from '../../../shared/components/Button/Button';
import { closeOverlay } from '../../../app/store/overlaySlice';

const mapStateToProps = (state: RootState) => ({
  tags: tagSelectors.selectAll(state),
});

const mapDispatchToProps = {
  closeOverlay,
  createTag,
  deleteTag,
};

interface State {
  newTagName: string | null;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class TagSettings extends React.Component<Props, State> {
  private input: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      newTagName: null,
    };

    this.input = React.createRef();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'Enter') {
      this.onSaveTagClick();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.newTagName === null && this.state.newTagName !== null) {
      this.input.current?.focus();
    }
  }

  onCloseClick() {
    this.props.closeOverlay();
  }

  onAddTagClick() {
    this.setState({
      newTagName: '',
    });
  }

  onSaveTagClick() {
    if (!this.state.newTagName) {
      return;
    }

    const payload = {
      body: {
        color: '#fff',
        name: this.state.newTagName,
        description: null,
      },
    };

    this.props
      .createTag(payload)
      .unwrap()
      .then(() => {
        this.setState({
          newTagName: '',
        });
      });
  }

  onDeleteTagClick(id: number) {
    this.props
      .deleteTag({
        id,
      })
      .unwrap()
      .then(() => {});
  }

  onClick() {}

  render() {
    return (
      <OverlayWrapper className="">
        <OverlayHeader
          showDivider={true}
          title="Uredi oznake"
          icon={Icons.TAG}
          subtitle={''}
        ></OverlayHeader>

        <OverlayBody>
          <div className="tag-settings-wrapper">
            <div className="tag-list">
              {this.props.tags.map((tag) => {
                return (
                  <div className="tag-list-item">
                    <span className="tag-icon">
                      <Icon icon={Icons.HASH} size={20} />
                    </span>

                    {tag.name}
                    <div
                      className="tag-right-button"
                      onClick={() => this.onDeleteTagClick(tag.id)}
                    >
                      <Icon icon={Icons.X} size={20} />
                    </div>
                  </div>
                );
              })}
              {this.state.newTagName !== null && (
                <div className="tag-list-item">
                  <span className="tag-icon">
                    <Icon icon={Icons.HASH} size={20} />
                  </span>

                  <input
                    onBlur={() =>
                      this.setState({
                        newTagName: null,
                      })
                    }
                    className="name-input"
                    ref={this.input}
                    value={this.state.newTagName}
                    onChange={(e) =>
                      this.setState({
                        newTagName: e.target.value,
                      })
                    }
                    type="text"
                  />
                  <div
                    className="tag-right-button"
                    onClick={() => this.onSaveTagClick()}
                  >
                    <Icon icon={Icons.CHECK} size={20} />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="tag-utils">Trenutni tag</div> */}
          </div>
        </OverlayBody>
        <OverlayFooter>
          <div className="copy-link" onClick={() => this.onAddTagClick()}>
            <Icon icon={Icons.PLUS} size={20}></Icon>
            <span>Add tag</span>
          </div>
          <Button text="Zapri" onClick={() => this.onCloseClick()} />
          <Button text="Končaj" onClick={() => this.onCloseClick()} primary />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(TagSettings);
