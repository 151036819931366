import { Toaster } from 'react-hot-toast';

const Toast: React.FC = () => (
  <Toaster
    toastOptions={{
      success: {
        position: 'bottom-left',
        style: {
          borderRadius: '4px',
          color: 'rgb(23, 43, 77)',
          fontFamily: 'CircularStdMedium',
          background: 'white',
        },
      },
    }}
  />
);

export default Toast;
