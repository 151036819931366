import React from 'react';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';

interface OwnProps {
  id: number;
  name: string;
  onClick: (id: number) => void;
}

type Props = OwnProps;

class PathFragment extends React.Component<Props> {
  render() {
    return (
      <div
        className="fragment-wrapper"
        onClick={() => this.props.onClick(this.props.id)}
        key={this.props.id}
      >
        <Icon icon={Icons.CHEVRONS_RIGHT} size={28} />
        <div className="fragment">
          <Icon icon={Icons.FOLDER} size={24} />
          <span>{this.props.name}</span>
        </div>
      </div>
    );
  }
}

export default PathFragment;
