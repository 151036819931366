import client from '../../app/axiosClient';

class GrantAdapter {
  static async activate(key: string) {
    const grant = await client.post('/grant/activate/' + key);

    return grant.data;
  }

  static async update(body: any) {
    const grant = await client.put('/grant', body);

    return grant.data;
  }
}

export default GrantAdapter;
