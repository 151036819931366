import {
  Routes as Switch,
  Route,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import Home from '../modules/Home';
import Login from '../modules/Login';
import Share from '../modules/Share';
import PrivateRoute from '../shared/components/PrivateRoute';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Navigate to="/app" replace />}></Route>
      <Route
        path="/share/:key"
        element={
          <PrivateRoute>
            <Share />
          </PrivateRoute>
        }
      />
      <Route
        path="/app"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
