import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getStoredAuthToken,
  removeStoredAuthToken,
  storeAuthToken,
} from '../../shared/utils/token';
import api from '../axiosClient';

interface InitialState {
  token: string | null;
  loading: boolean;
  userData: any;
}

const initialState: InitialState = {
  token: null,
  loading: false,
  userData: null,
};

export const login = createAsyncThunk('auth/login', async (payload: any) => {
  const response = await api.post('/auth/login', payload);

  storeAuthToken(response.data.accessToken);

  return response.data;
});

export const signOut = createAsyncThunk('auth/sign-out', async (data) => {
  removeStoredAuthToken();
  return null;
});

export const fetchUserData = createAsyncThunk(
  'auth/fetch-user-data',
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getStoredAuthToken();
      console.log(accessToken);
      if (!accessToken) {
        throw new Error('No token.');
      }

      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      const response = await api.get('/auth/user');

      return {
        user: response.data.user,
        accessToken,
      };
    } catch (error) {
      removeStoredAuthToken();
      return rejectWithValue('');
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state) => {
      state.loading = false;
      state.userData = null;
      state.token = null;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      const { accessToken, user } = action.payload;

      state.token = accessToken;
      state.userData = user;
      state.loading = false;
    });

    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      const { accessToken, user } = action.payload;

      state.userData = user || null;
      state.loading = false;
      state.token = accessToken;
    });

    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.loading = false;
      state.userData = null;
      state.token = null;
    });

    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchUserData.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export default authSlice.reducer;
