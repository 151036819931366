import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { OverlayType } from '../../app/Modal';
import { fetchAll } from '../../app/store/folderSlice';
import { openOverlay } from '../../app/store/overlaySlice';
import GrantAdapter from '../../shared/adapters/grant';

const Share: React.FC = () => {
  console.log('Share ');
  const { key } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const accessKey = key;

    console.log(key);

    if (!accessKey) {
      return navigate('/app');
    }

    GrantAdapter.activate(accessKey).then((grant) => {
      console.log(grant);
      if (grant.errors) {
        return navigate('/app');
      }

      if (grant.folderId) {
        dispatch(fetchAll(grant.folderId));
        return navigate('/app');
      }

      if (grant.fileId) {
        dispatch(
          openOverlay({
            overlayType: OverlayType.FILE_PREVIEW,
            overlayData: { id: grant.fileId },
          })
        );

        return navigate('/app');
      }
    });
  }, [dispatch, key, navigate]);

  return <div>Activating grant...</div>;
};

export default Share;
