import React from 'react';

interface OwnProps {
  className: string;
}

type Props = OwnProps;

class OverlayWrapper extends React.Component<Props> {
  render() {
    return (
      <div className={`overlay ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default OverlayWrapper;
