import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Navigate } from 'react-router-dom';
import { RootState } from '../../../app/store/store';

const PrivateRoute: React.FC = ({ children }) => {
  const auth = useSelector((state: RootState) => state.auth);

  if (auth.loading) {
    return <div>Loading ...</div>;
  }

  if (!auth.token) {
    return <Navigate to={'/login'} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
