import './styles.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { dequeue, enqueue, selectLast } from '../../../app/store/queueSlice';
import { RootState } from '../../../app/store/store';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import { formatBytes } from '../../../shared/utils/formatBytes';
import LinearProgressBar from '../../../shared/components/LinearProgressBar';
import FileAdapter from '../../../shared/adapters/file';
import { addUploadedFile } from '../../../app/store/fileSlice';
import { closeUploadQueue } from '../../../app/store/overlaySlice';

const mapDispatchToProps = {
  enqueue,
  dequeue,
  addUploadedFile,
  closeUploadQueue,
};

const mapStateToProps = (state: RootState) => ({
  queue: state.queue,
  last: selectLast(state),
  ui: state.overlay,
  currentFolderId: state.folders.currentFolderId,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

interface State {
  extended: boolean;
  uploadInProgress: boolean;
  currentProgress: number;
  errors: any[];
}

class UploadQueue extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      extended: true,
      uploadInProgress: false,
      currentProgress: 0,
      errors: [],
    };
  }

  async processFile() {
    const item = this.props.queue.pending[this.props.queue.pending.length - 1];

    if (!item) {
      return;
    }

    const { name, type, url, parentFolderId } = item.data;

    const file = await fetch(url)
      .then((data) => data.blob())
      .then(
        (blobFile) =>
          new File([blobFile], name, {
            type,
          })
      );

    FileAdapter.uploadFile(
      {
        name,
        parentFolderId,
        file,
      },
      (progress) => {
        console.log('Hello: ', progress);
        this.setState({
          currentProgress: progress,
        });
      }
    )
      .catch((err) => {
        console.log(err);
      })
      .then((uploadedFile) => {
        this.props.dequeue();

        if (uploadedFile.folderId === this.props.currentFolderId) {
          this.props.addUploadedFile(uploadedFile);
        }

        this.setState({
          uploadInProgress: false,
          currentProgress: 0,
        });
      });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.queue.lastIdx !== this.props.queue.lastIdx &&
      this.state.uploadInProgress === false
    ) {
      // Initiate the uploading
      this.processFile();
    }

    if (this.props.queue.pending.length === 0) {
      this.props.closeUploadQueue();
    }
  }

  toggleExtend() {
    this.setState({
      extended: !this.state.extended,
    });
  }

  render() {
    if (!this.props.ui.isUploadQueueOpened) {
      return null;
    }

    console.log(this.state.currentProgress);

    return (
      <div className={`upload-queue ${this.state.extended ? 'extend' : ''}`}>
        <div className="upload-queue-header">
          <div className="upload-queue-header-text">Upload queue</div>
          <div
            className="upload-queue-header-actions"
            onClick={() => this.toggleExtend()}
          >
            {this.state.extended && (
              <Icon icon={Icons.CHEVRON_DOWN} size={24} />
            )}
            {!this.state.extended && <Icon icon={Icons.CHEVRON_UP} size={24} />}
          </div>
        </div>
        {this.state.extended && (
          <div className="upload-queue-body">
            {this.props.queue.pending.map((item, i) => {
              const { name, size } = item.data;
              const progress =
                i === this.props.queue.lastIdx - 1
                  ? this.state.currentProgress
                  : 0;
              return (
                <div className="upload-queue-item" key={i}>
                  <div className="upload-queue-item-icon-wrapper">
                    <Icon icon={Icons.FILE_TEXT} size={24} />
                    {formatBytes(size)}
                  </div>
                  <div className="upload-queue-item-title">
                    <span className="upload-queue-item-text">
                      <span>{name}</span>
                      <span
                        style={{
                          marginLeft: 'auto',
                        }}
                      >
                        {progress.toFixed(0)} %
                      </span>
                    </span>
                    <LinearProgressBar
                      max={100}
                      barColor={'#4958fa'}
                      backgroundColor="white"
                      completed={parseInt(progress.toFixed(0))}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default connector(UploadQueue);
