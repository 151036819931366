import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import TagAdapter from '../../shared/adapters/tag';
import { RootState } from './store';

export interface Tag {
  id: number;
  color: string;
  description: string;
  name: string;
}

const tagsAdapter = createEntityAdapter<Tag>({
  selectId: (tag) => tag.id,
});

export const fetchAllTags = createAsyncThunk('tags/fetchAll', async () => {
  return await TagAdapter.fetchAll();
});

export const createTag = createAsyncThunk('tags/create', async (data: any) => {
  return await TagAdapter.create(data.body);
});

export const deleteTag = createAsyncThunk('tags/delete', async (data: any) => {
  await TagAdapter.delete(data.id);

  return {
    id: data.id,
  };
});

const tagsSlice = createSlice({
  name: 'tags',
  initialState: tagsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteTag.fulfilled, (state, action) => {
      tagsAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(createTag.fulfilled, tagsAdapter.addOne);
    builder.addCase(fetchAllTags.fulfilled, tagsAdapter.upsertMany);
  },
});

export const tagSelectors = tagsAdapter.getSelectors(
  (state: RootState) => state.tags
);

export default tagsSlice.reducer;
