import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeOverlay } from '../../app/store/overlaySlice';
import Icon, { Icons } from './Icon/Icon';

interface OwnProps {
  title: string;
  icon: Icons;
  subtitle: string;
  showDivider: boolean;
}

const mapDispatchToProps = {
  closeOverlay,
};

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

class OverlayHeader extends React.Component<Props> {
  handleClick() {
    this.props.closeOverlay();
  }

  render() {
    return (
      <>
        <div className={'overlay-header'}>
          <div className="overlay-header-title">
            <Icon icon={this.props.icon} size={32} />
            <div className="overlay-header-text">
              {this.props.title}
              <div className="overlay-header-subtitle">
                {this.props.subtitle}
              </div>
            </div>
          </div>
          <div
            className="overlay-header-close"
            onClick={() => this.handleClick()}
          >
            <Icon icon={Icons.X} size={32} />
          </div>
        </div>
        {this.props.showDivider && <hr className="overlay-header-divider" />}
      </>
    );
  }
}

export default connector(OverlayHeader);
