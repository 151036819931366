import React from 'react';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import { DataType } from '../../../app/store/activeSlice';
import { RootState } from '../../../app/store/store';
import { connect, ConnectedProps } from 'react-redux';

interface OwnProps {
  type: DataType;
  title: string;
}

const mapStateToProps = (state: RootState) => ({
  grant: state.active.selected.grant,
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & OwnProps;

class Header extends React.Component<Props> {
  getHeaderIcon() {
    return this.props.type === DataType.FILE ? (
      <Icon icon={Icons.FILE_TEXT} size={36} />
    ) : (
      <Icon icon={Icons.FOLDER} size={36} />
    );
  }

  getMainIcon() {
    return this.props.type === DataType.FILE ? (
      <Icon icon={Icons.FILE_TEXT} size={128} strokeWidth={1} />
    ) : (
      <Icon icon={Icons.FOLDER} size={128} strokeWidth={1} />
    );
  }

  render() {
    const { grant } = this.props;

    return (
      <>
        <div className="details-header">
          <div className="details-icon">{this.getHeaderIcon()}</div>
          <div className="details-text">{this.props.title}</div>
          <div className="details-close">
            <div className="icon-bg">
              {grant && grant.restricted === 0 && (
                <Icon icon={Icons.GLOBE} size={24} />
              )}
              {grant &&
                grant.restricted === 1 &&
                grant.members.length === 0 && (
                  <Icon icon={Icons.LOCK} size={24} />
                )}
              {grant && grant.restricted === 1 && grant.members.length > 0 && (
                <Icon icon={Icons.USERS} size={24} />
              )}
            </div>
          </div>
        </div>
        <div className="details-rep">{this.getMainIcon()}</div>
      </>
    );
  }
}

export default connector(Header);
