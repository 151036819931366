import React from 'react';
import Dragable from '.';

interface State {
  draggedElementId: number | null;
  hoveredElementId: number | null;
}

interface OwnProps {
  canDrop: boolean;
  elements: JSX.Element[];
  onDrop?: (data: State) => void;
}

type Props = OwnProps;

class Dropzone extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      draggedElementId: null,
      hoveredElementId: null,
    };
  }

  onDrop() {
    if (this.state.draggedElementId === this.state.hoveredElementId) {
      return;
    }

    if (this.props.onDrop && typeof this.props.onDrop === 'function') {
      this.props.onDrop({
        ...this.state,
      });
    }
  }

  onDragStart(idx: number) {
    this.setState({
      draggedElementId: idx,
    });
  }

  onDragEnter(idx: number) {
    this.setState({
      hoveredElementId: idx,
    });
  }

  onDragEnd() {
    this.setState({
      hoveredElementId: null,
      draggedElementId: null,
    });
  }

  render() {
    return (
      <>
        {this.props.elements.map((element) => {
          return (
            <Dragable
              id={element.props.folder.id}
              onDrop={() => this.onDrop()}
              onDragEnd={() => this.onDragEnd()}
              hoveringId={this.state.hoveredElementId}
              draggedId={this.state.draggedElementId}
              canDropInto={this.props.canDrop}
              onDragEnter={(idx) => this.onDragEnter(idx)}
              onDragStart={(idx) => this.onDragStart(idx)}
            >
              {element}
            </Dragable>
          );
        })}
      </>
    );
  }
}

export default Dropzone;
