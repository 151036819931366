import React from 'react';

interface OwnProps {
  color: string;
  name: string;
  index: number;
}

type Props = OwnProps;

class Tag extends React.Component<Props> {
  render() {
    return (
      <div
        style={{
          color: this.props.color,
        }}
        className="tag"
        key={this.props.index}
      >
        {this.props.name}
      </div>
    );
  }
}

export default Tag;
