import './styles.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeOverlay } from '../../../../app/store/overlaySlice';
import { RootState } from '../../../../app/store/store';
import OverlayWrapper from '../../../../shared/components/OverlayWrapper';
import OverlayHeader from '../../../../shared/components/OverlayHeader';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import OverlayBody from '../../../../shared/components/OverlayBody';
import { fileSelectors } from '../../../../app/store/fileSlice';
import OverlayFooter from '../../../../shared/components/OverlayFooter';
import toast from 'react-hot-toast';
import FileAdapter from '../../../../shared/adapters/file';
import { formatName } from '../../../../shared/utils/formatName';

interface OwnProps {
  id: number;
}

const mapDispatchToProps = {
  closeOverlay,
};

const mapStateToProps = (state: RootState) => ({
  selected: state.active.selected,
  files: fileSelectors.selectAll(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

interface State {
  file: any;
  previewBlob: Blob | null;
}

class FilePreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      previewBlob: null,
    };
  }

  async componentDidMount() {
    const data = await FileAdapter.fetch(this.props.id);

    const previewURI = data.accessURIs.previewURI;

    const previewBlob = await FileAdapter.downloadFile(previewURI);

    this.setState({
      file: data,
      previewBlob,
    });
  }

  async onDownloadClick() {
    const { name, previewSavedName, suffix, accessURIs } = this.state.file;

    const blob = await FileAdapter.downloadFile(accessURIs.downloadURI);
    const url = URL.createObjectURL(blob);

    const downloadName = formatName(name, suffix, previewSavedName);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', downloadName);

    a.click();
    URL.revokeObjectURL(url);
  }

  onCopyClick() {
    console.log('Copying commencing.');
    const url = `https://drive.purplebear.io/share/${this.state.file.grant.accessKey}`;

    navigator.clipboard.writeText(url).then(() => {
      toast.success('Povezava uspešno kopirana.');
    });
  }

  render() {
    if (!this.state.file) {
      return null;
    }

    const { name, description } = this.state.file;

    return (
      <OverlayWrapper className="file-preview">
        <OverlayHeader
          icon={Icons.FILE_TEXT}
          title={name}
          subtitle={description || ''}
          showDivider={false}
        />
        <OverlayBody>
          <div className="preview-wrapper">
            <div className="preview">
              {this.state.previewBlob !== null ? (
                <iframe
                  className="preview-frame"
                  src={URL.createObjectURL(this.state.previewBlob)}
                  title="File preview"
                  seamless
                ></iframe>
              ) : (
                <div>Tega tipa datoteke se v brskalniku ne da prikazati</div>
              )}
            </div>
          </div>
        </OverlayBody>
        <OverlayFooter>
          <div className="preview-action" onClick={() => this.onCopyClick()}>
            <Icon icon={Icons.CLIPBOARD} size={18} />
            Copy share link
          </div>
          <div
            className="preview-action"
            onClick={() => this.onDownloadClick()}
          >
            <Icon icon={Icons.DOWNLOAD} size={18} />
            Download file
          </div>
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(FilePreview);
