import './styles.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeOverlay, openOverlay } from '../../../app/store/overlaySlice';
import OverlayBody from '../../../shared/components/OverlayBody';
import OverlayFooter from '../../../shared/components/OverlayFooter';
import OverlayHeader from '../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../shared/components/OverlayWrapper';
import { RootState } from '../../../app/store/store';
import TextArea from '../../../shared/components/TextArea';
import Input from '../../../shared/components/Input/Index';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import Button from '../../../shared/components/Button/Button';
import { createFolder } from '../../../app/store/folderSlice';
import { OverlayType } from '../../../app/Modal';

interface State {
  name: string;
  description: string;
}

const mapDispatchToProps = {
  createFolder,
  closeOverlay,
  openOverlay,
};

const mapStateToProps = (state: RootState) => ({
  currentFolderId: state.folders.currentFolderId,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class FolderCreate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      description: '',
    };
  }

  onInput = (value: string, name: string) => {
    this.setState({
      [name]: value,
    } as Pick<State, keyof State>);
  };

  onCloseClick() {
    this.props.closeOverlay();
  }

  onSaveClick() {
    const { name, description } = this.state;

    this.props
      .createFolder({
        name,
        description,
        parentFolderId: this.props.currentFolderId,
      })
      .unwrap()
      .then(() => {
        this.props.closeOverlay();
      });
  }

  onBackClick() {
    this.props.openOverlay({
      overlayType: OverlayType.CREATE_MENU,
      overlayData: {},
    });
  }

  render() {
    return (
      <OverlayWrapper className="">
        <OverlayHeader
          showDivider={true}
          icon={Icons.FOLDER_PLUS}
          title={'Dodaj mapo'}
          subtitle={'v mapo "Moj disk"'}
        />

        <OverlayBody>
          {/* <span
            style={{
              color: 'rgb(94, 108, 132)',
              marginBottom: '6px',
              fontFamily: 'CircularStdMedium',
            }}
          >
            Ime datoteke
          </span> */}
          <Input
            icon={Icons.CALENDAR}
            name="name"
            value={this.state.name}
            setValue={this.onInput}
            placeholder={'Ime mape...'}
            type="text"
          />
          {/* <span
            style={{
              color: 'rgb(94, 108, 132)',
              marginBottom: '6px',
              fontFamily: 'CircularStdMedium',
            }}
          >
            Opis datoteke
          </span> */}
          <TextArea
            name="description"
            value={this.state.description}
            setValue={this.onInput}
            placeholder="Opis mape"
            rows={5}
          />
        </OverlayBody>

        <OverlayFooter>
          <span className="back-icon" onClick={() => this.onBackClick()}>
            <Icon size={32} icon={Icons.CHEVRON_LEFT}></Icon>
          </span>
          <Button className='overlay-button' text="Zapri" onClick={() => this.onCloseClick()} />
          <Button text="Shrani" primary onClick={() => this.onSaveClick()} />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(FolderCreate);
