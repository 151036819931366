import React from 'react';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import PathFragment from './PathFragment';

interface OwnProps {
  onFragmentClick: (id: number | null) => void;
  onPlusClick: () => void;
  pathFragments: any[];
}

type Props = OwnProps;

class FolderPath extends React.Component<Props> {
  render() {
    return (
      <div className="utility-folder-path">
        <div className="utility-context-fragment">
          <span onClick={() => this.props.onPlusClick()}>
            <Icon icon={Icons.PLUSCIRCLE} size={28}></Icon>
          </span>
          <span onClick={() => this.props.onFragmentClick(null)}>Moj disk</span>
        </div>
        {this.props.pathFragments.map((fragment) => (
          <PathFragment
            id={fragment.id}
            key={fragment.id}
            name={fragment.name}
            onClick={(id) => this.props.onFragmentClick(id)}
          />
        ))}
      </div>
    );
  }
}

export default FolderPath;
