import client from '../../app/axiosClient';

class FolderAdapter {
  static async fetchFolders(id: number | string) {
    const folders = await client.get('/folder/folders/' + id);

    return folders.data;
  }

  static async downloadFolder(id: number) {
    const type = 'attachment';

    const response = await client.get(`/dl/folder/${id}/${type}`, {
      responseType: 'blob',
      withCredentials: false,
    });

    return response.data;
  }

  static async create(body: any) {
    const folder = await client.post('/folder', body);

    return folder.data;
  }

  static async update(id: number, body: any) {
    const folder = await client.put('/folder/' + id, body);

    return folder.data;
  }

  static async delete(id: number) {
    await client.delete('/folder/' + id);
  }

  static async fetch(id: number) {
    const folder = await client.get('/folder/single/' + id);

    return folder.data;
  }

  static async move(id: number, body: any) {
    const folder = await client.put('/folder/move/' + id, body);

    return folder.data;
  }
}

export default FolderAdapter;
