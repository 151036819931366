import React from 'react';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';

interface OwnProps {
  icon: Icons;
  onClick: () => void;
}

type Props = OwnProps;

class Action extends React.Component<Props> {
  render() {
    return (
      <div className="action" onClick={this.props.onClick}>
        <Icon icon={this.props.icon} size={32} />
      </div>
    );
  }
}

export default Action;
