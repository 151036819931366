import axios from 'axios';
import { BASE_URL } from '../shared/constants';
import { getStoredAuthToken } from '../shared/utils/token';

const defaults = {
  baseURL: process.env.API_URL || BASE_URL,
  headers: () => ({
    'Content-Type': 'application/json',
    Authorization: getStoredAuthToken()
      ? `Bearer ${getStoredAuthToken()}`
      : undefined,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const client = axios.create({
  baseURL: defaults.baseURL,
  headers: {
    Authorization: getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : '',
  },
});

client.interceptors.request.use(function (config) {
  const token = getStoredAuthToken();

  if (config.headers) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

export default client;
