import styles from 'styled-components';

interface Props {
  barColor: string;
  backgroundColor: string;
  completed: number;
  max: number;
}

export const LinearProgressBar = styles.div<Props>`
  border-radius: 20px;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, ${(props) => props.barColor} ${(
  props
) => props.completed}%, ${(props) => props.backgroundColor} 0.1%, ${(props) =>
  props.backgroundColor} ${(props) => props.max - props.completed}%)
`;

// .linear-progress-bar {
//   border-radius: 20px;
//   background: linear-gradient(to right, #4958fa 40%, white 0.1%, white 60%);
//   width: 100%;
//   height: 4px;
// }
