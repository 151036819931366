import { useContext } from 'react';
import File from '../File';
import FileExplorerContext from '../FileExplorerContext';
import DriveRoot from './DriveRoot';
import FolderEntry from './FolderEntry';

interface FolderProps {
  id: number;
  name: string;
  contents: any;
  onClick: (id: any) => Promise<void>;
  onFileClick: (file: any) => void;
}

const Folder: React.FC<FolderProps> = ({
  id,
  name,
  onClick,
  onFileClick,
  contents,
}) => {
  const { cache, openedFolders, active } = useContext(FileExplorerContext);

  const generateElements = () => {
    return (
      <div className="contents">
        {contents.folders.map((folder: any) => {
          return (
            <Folder
              contents={cache[folder.id]}
              onFileClick={onFileClick}
              onClick={onClick}
              id={folder.id}
              name={folder.name}
            ></Folder>
          );
        })}
        {contents.files.map((file: any) => (
          <File id={file.id} name={file.name} onClick={onFileClick}></File>
        ))}
      </div>
    );
  };

  const isOpened = () => {
    if (id === null) return true;
    return openedFolders[id] === true;
  };

  return (
    <div className="folder" style={{ marginLeft: id === 0 ? 0 : '14px' }}>
      {id === 0 ? (
        <DriveRoot
          id={id}
          isOpened={isOpened()}
          name={name}
          onClick={onClick}
        ></DriveRoot>
      ) : (
        <FolderEntry
          id={id}
          isActive={active === id}
          isOpened={isOpened()}
          name={name}
          onClick={onClick}
        ></FolderEntry>
      )}

      {isOpened() && generateElements()}
    </div>
  );
};

export default Folder;
