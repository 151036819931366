import '../../../../shared/styles/DataUpdate.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DataType } from '../../../../app/store/activeSlice';
import { closeOverlay } from '../../../../app/store/overlaySlice';
import { RootState } from '../../../../app/store/store';
import Button from '../../../../shared/components/Button/Button';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import Input from '../../../../shared/components/Input/Index';
import OverlayBody from '../../../../shared/components/OverlayBody';
import OverlayFooter from '../../../../shared/components/OverlayFooter';
import OverlayHeader from '../../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../../shared/components/OverlayWrapper';
import Select from '../../../../shared/components/Select';
import Value from '../../../../shared/components/Select/Value';
import { tagSelectors } from '../../../../app/store/tagSlice';
import { updateFolder } from '../../../../app/store/folderSlice';
import { updateFile } from '../../../../app/store/fileSlice';

const mapStateToProps = (state: RootState) => ({
  selected: state.active.selected,
  type: state.active.type,
  tags: tagSelectors.selectAll(state),
});

const mapDispatchToProps = {
  closeOverlay,
  updateFile,
  updateFolder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

interface State {
  name: string;
  description: string | null;
  tags: number[];
}

class DataUpdate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      description: null,
      tags: [],
    };
  }

  componentDidMount() {
    this.setState({
      name: this.props.selected.name,
      description: this.props.selected.description,
      tags: this.props.selected.tags.map((tag: any) => tag.id),
    });
  }

  onInput = (value: unknown, name: string) => {
    this.setState({
      [name]: value,
    } as Pick<State, keyof State>);
  };

  onCloseClick() {
    this.props.closeOverlay();
  }

  onSaveClick() {
    const { id } = this.props.selected;
    const payload = {
      id,
      body: {
        name: this.state.name,
        description: this.state.description,
        tags: this.state.tags,
      },
    };

    console.log(payload);

    const promise =
      this.props.type === DataType.FILE
        ? this.props.updateFile(payload)
        : this.props.updateFolder(payload);

    promise.unwrap().then((_) => {
      this.props.closeOverlay();
    });
  }

  onChange(value: any[]) {
    this.setState({
      tags: value,
    });
  }

  render() {
    return (
      <OverlayWrapper className="">
        <OverlayHeader
          showDivider={true}
          icon={Icons.EDIT2}
          title="Uredi datoteko"
          subtitle={this.state.name}
        />
        <OverlayBody>
          <div className="body-section">
            <div className="body-section-icon">
              <Icon icon={Icons.INFO} size={26} />
            </div>
            <div className="body-section-title">Osnovne informacije</div>
          </div>
          <Input
            type="text"
            value={this.state.name}
            name="name"
            setValue={this.onInput}
            placeholder={'Ime datoteke ...'}
          />
          <Input
            type="text"
            value={this.state.description}
            name="description"
            setValue={this.onInput}
            placeholder={'Opis datoteke ...'}
          />
          <div className="body-section">
            <div className="body-section-icon">
              <Icon icon={Icons.TAG} size={26} />
            </div>
            <div className="body-section-title">Oznake</div>
          </div>
          <div className="tag-wrapper">
            <Select
              defaultValue="Untagged"
              values={this.state.tags}
              options={this.props.tags}
              onChange={(values) => this.onChange(values)}
              renderValue={({ value, removeOptionValue }) => {
                const opt = this.props.tags.find(
                  (opt) => opt.id === Number(value)
                );

                return <Value onRemove={removeOptionValue} value={opt!.name} />;
              }}
              renderOption={(option) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <span
                      style={{
                        color: '#4957fa',
                        display: 'flex',
                      }}
                    >
                      <Icon icon={Icons.TAG} size={18} strokeWidth={3} />
                    </span>
                    {option.name}
                  </div>
                );
              }}
            />
          </div>
        </OverlayBody>
        <OverlayFooter>
          <Button text="Zapri" onClick={() => this.onCloseClick()} />
          <Button text="Shrani" onClick={() => this.onSaveClick()} primary />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(DataUpdate);
