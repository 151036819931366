import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeOverlay } from '../../../../app/store/overlaySlice';
import { DataType } from '../../../../app/store/activeSlice';
import { RootState } from '../../../../app/store/store';
import Button from '../../../../shared/components/Button/Button';
import { Icons } from '../../../../shared/components/Icon/Icon';
import OverlayBody from '../../../../shared/components/OverlayBody';
import OverlayFooter from '../../../../shared/components/OverlayFooter';
import OverlayHeader from '../../../../shared/components/OverlayHeader';
import OverlayWrapper from '../../../../shared/components/OverlayWrapper';
import { deleteFolder } from '../../../../app/store/folderSlice';
import { deleteFile } from '../../../../app/store/fileSlice';

const mapStateToProps = (state: RootState) => ({
  selected: state.active.selected,
  type: state.active.type,
});

const mapDispatchToProps = {
  closeOverlay,
  deleteFile,
  deleteFolder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class DataDelete extends React.Component<Props> {
  onCloseClick() {
    this.props.closeOverlay();
  }

  onSaveClick() {
    const payload = {
      id: this.props.selected.id,
    };

    const promise =
      this.props.type === DataType.FILE
        ? this.props.deleteFile(payload)
        : this.props.deleteFolder(payload);

    promise.unwrap().then((_) => {
      this.props.closeOverlay();
    });
  }

  render() {
    if (!this.props.selected) {
      return null;
    }

    return (
      <OverlayWrapper className="">
        <OverlayHeader
          showDivider={true}
          icon={Icons.TRASH2}
          title="Premakni v koš"
          subtitle={this.props.selected.name}
        />
        <OverlayBody>
          <div className="confirm-text">
            <span>Ste prepričani, da želite </span>
            <u>"{this.props.selected.name}"</u> <span> premakniti v koš?</span>
          </div>
        </OverlayBody>
        <OverlayFooter>
          <Button text="Zapri" onClick={() => this.onCloseClick()} />
          <Button text="Izbriši" onClick={() => this.onSaveClick()} primary />
        </OverlayFooter>
      </OverlayWrapper>
    );
  }
}

export default connector(DataDelete);
