import '../../shared/styles/Overlay.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { connect, ConnectedProps } from 'react-redux';
import { closeOverlay } from '../store/overlaySlice';
import { RootState } from '../store/store';
import FolderCreate from '../../modules/Home/FolderCreate';
import GrantUpdate from '../../modules/Home/GrantUpdate';
import DataUpdate from '../../modules/Home/DetailsBar/DataUpdate';
import DataDelete from '../../modules/Home/DetailsBar/DataDelete';
import DataMove from '../../modules/Home/DetailsBar/DataMove';
import TagSettings from '../../modules/Home/TagSettings';
import CreateMenu from '../../modules/Home/TopActionBar/CreateMenu';
import FilePreview from '../../modules/Home/DataList/FilePreview';
import FilterSettings from '../../modules/Home/FilterSettings';

export enum OverlayType {
  ADD_FOLDER = 'add_folder',
  UPDATE_GRANT = 'update_grant',
  UPDATE_DATA = 'update_data',
  DELETE_DATA = 'delete_data',
  MOVE_DATA = 'move_data',
  TAG_SETTINGS = 'tag_settings',
  CREATE_MENU = 'create_menu',
  FILE_PREVIEW = 'file_preview',
  FILTER_SETTINGS = 'filter_settings',
}

interface State {
  isEscapePressed: boolean;
}

const mapDispatchToProps = {
  closeOverlay,
};

const mapStateToProps = (state: RootState) => ({
  overlay: state.overlay,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const MODALS = {
  [OverlayType.ADD_FOLDER]: FolderCreate,
  [OverlayType.UPDATE_GRANT]: GrantUpdate,
  [OverlayType.UPDATE_DATA]: DataUpdate,
  [OverlayType.DELETE_DATA]: DataDelete,
  [OverlayType.MOVE_DATA]: DataMove,
  [OverlayType.TAG_SETTINGS]: TagSettings,
  [OverlayType.CREATE_MENU]: CreateMenu,
  [OverlayType.FILE_PREVIEW]: FilePreview,
  [OverlayType.FILTER_SETTINGS]: FilterSettings,
};

class Overlay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEscapePressed: false,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.setState({
        isEscapePressed: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.isEscapePressed) {
      // dispatch the close overlay component
      this.setState({
        isEscapePressed: false,
      });

      this.props.closeOverlay();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  render() {
    if (
      this.props.overlay.isOverlayOpened === false ||
      this.props.overlay.overlayType === null
    ) {
      return null;
    }

    const Modal = MODALS[this.props.overlay.overlayType];

    return ReactDOM.createPortal(
      <>
        <div className="overlay-wrapper">
          <Modal {...this.props.overlay.overlayData} />
        </div>
      </>,
      document.querySelector('div#modal-root')!
    );
  }
}
  
export default connector(Overlay);
