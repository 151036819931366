import React from 'react';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';

interface FolderEntryProps {
  isOpened: boolean;
  name: string;
  onClick: (id: number) => void;
  id: number;
  isActive: boolean;
}

const FolderEntry: React.FC<FolderEntryProps> = ({
  isOpened,
  onClick,
  name,
  id,
  isActive,
}) => {
  const iconIndicator = !isOpened ? Icons.CHEVRON_RIGHT : Icons.CHEVRON_DOWN;

  return (
    <div className={`folder-name ${isActive ? 'active' : ''}`}>
      <span className="opened-indicator">
        <Icon icon={iconIndicator} size={24}></Icon>
      </span>
      <span className="folder-icon">
        <Icon icon={Icons.FOLDER} size={26}></Icon>
      </span>
      <span onClick={() => onClick(id)}>{name}</span>
    </div>
  );
};

export default FolderEntry;
