import { createSlice } from '@reduxjs/toolkit';
import { fetchAll } from './folderSlice';

interface InitialState {
  path: any[];
}

const initialState: InitialState = {
  path: [],
};

const pathSlice = createSlice({
  name: 'path',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.path = action.payload.path.reverse();
    });
  },
});

export default pathSlice.reducer;
