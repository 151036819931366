import Icon, { Icons } from '../../../../shared/components/Icon/Icon';

interface FolderListElementProps {
  folder: any;
  showDetails?: boolean;
  onClick: (id: number) => void;
  onDoubleClick: (id: number) => void;
  onDetailsClick: (id: number) => void;
}

const FolderListElement: React.FC<FolderListElementProps> = ({
  onDoubleClick,
  onClick,
  onDetailsClick,
  folder,
  showDetails = false,
}) => {
  return (
    <div
      className={'folder-list-element'}
      onClick={() => onClick(folder.id)}
      onDoubleClick={() => onDoubleClick(folder.id)}
    >
      <div className="element-icon">
        <Icon icon={Icons.FOLDER} size={36} />
      </div>

      <div className="element-name">{folder.name}</div>

      {showDetails && (
        <>
          <div
            className="element-type"
            style={{
              color: '#4957fa',
            }}
          >
            <Icon icon={Icons.MINUS} size={36} />
          </div>

          <div className="element-owner">Nejc Mikelj</div>

          <div className="element-date">
            {new Date(folder.createdAt).toLocaleDateString('sl')}
          </div>

          <div
            className="element-size"
            style={{
              color: '#4957fa',
            }}
          >
            <Icon icon={Icons.MINUS} size={36} />
          </div>

          <div
            className="element-details-button"
            onClick={() => onDetailsClick(folder.id)}
          >
            <Icon icon={Icons.CHEVRONS_RIGHT} size={36} />
          </div>
        </>
      )}
    </div>
  );
};

export default FolderListElement;
