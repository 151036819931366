import React from 'react';

interface OwnProps {
  canDropInto: boolean;
  onDragStart: (id: any) => void;
  onDragEnter: (id: any) => void;
  onDrop?: () => void;
  onDragEnd: () => void;
  id: number;
  draggedId: number | null;
  hoveringId: number | null;
}

type Props = OwnProps;

class Dragable extends React.Component<Props> {
  render() {
    const isBeingDragged = this.props.draggedId === this.props.id;
    const isBeingHovered = this.props.hoveringId === this.props.id;

    return (
      <div
        style={{
          borderRadius: '32px',
          backgroundColor: isBeingDragged
            ? 'whitesmoke'
            : isBeingHovered
            ? '#4958fa34'
            : 'initial',
        }}
        onDragEnd={() => this.props.onDragEnd()}
        onDragStart={() => this.props.onDragStart(this.props.id)}
        onDragEnter={() => this.props.onDragEnter(this.props.id)}
        onDrop={!this.props.canDropInto ? undefined : this.props.onDrop}
        draggable
        onDragOver={
          this.props.canDropInto ? (e) => e.preventDefault() : undefined
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export default Dragable;
