import '../../styles/TextArea.css';

interface TextAreaProps {
  cols?: number;
  rows: number;
  name: string;
  placeholder: string;
  value: string | null;
  setValue: (value: string, name: string) => any;
}

const TextArea: React.FC<TextAreaProps> = ({
  setValue,
  value,
  cols,
  rows,
  name,
  placeholder,
}) => {
  return (
    <textarea
      className="textarea"
      value={value || ''}
      name={name}
      placeholder={placeholder}
      cols={cols}
      rows={rows}
      onChange={(e) => setValue(e.target.value, e.target.name)}
    ></textarea>
  );
};

export default TextArea;
