import React from 'react';
import Dropzone from '../../../../shared/components/Draggable/Dropzone';

import FileListElement from './FileListElement';

interface OwnProps {
  files: any[];
  dropzone: boolean;
  onDrop: (data: any) => void;
  onFileClick: (id: number) => void;
  onFileDoubleClick: (id: number) => void;
  onFileDetailsClick: (id: number) => void;
}

type Props = OwnProps;

class FileList extends React.Component<Props> {
  render() {
    const fileElements = this.props.files.map((file, i) => (
      <FileListElement
        key={i}
        onDoubleClick={(id) => this.props.onFileDoubleClick(id)}
        onDetailsClick={(id) => this.props.onFileDetailsClick(id)}
        onClick={(id) => this.props.onFileClick(id)}
        file={file}
      />
    ));

    if (this.props.dropzone) {
      return (
        <Dropzone onDrop={this.props.onDrop} canDrop elements={fileElements} />
      );
    }
    return <>{fileElements}</>;
  }
}

export default FileList;
