import '../../../shared/styles/Dataview.css';

import React from 'react';
import FolderList from './FolderList/FolderList';
import FileList from './FileList/FileList';
import { RootState } from '../../../app/store/store';
import { connect, ConnectedProps } from 'react-redux';
import { DataType, setActive, Status } from '../../../app/store/activeSlice';
import {
  createFolder,
  fetchAll,
  folderSelectors,
  updateFolder,
} from '../../../app/store/folderSlice';
import { fileSelectors } from '../../../app/store/fileSlice';
import { enqueue } from '../../../app/store/queueSlice';
import { openOverlay, openUploadQueue } from '../../../app/store/overlaySlice';
import { OverlayType } from '../../../app/Modal';

interface QueueEntry {
  entry: FileSystemEntry;
  parentFolderId: number | null;
}

const mapStateToProps = (state: RootState) => ({
  status: state.folders.status,
  folders: folderSelectors.selectAll(state),
  files: fileSelectors.selectAll(state),
  currentFolderId: state.folders.currentFolderId,
});

const mapDispatchToProps = {
  fetchAll,
  setActive,
  updateFolder,
  openUploadQueue,
  createFolder,
  enqueue,
  openOverlay,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

class DataList extends React.Component<Props> {
  componentDidMount() {
    if (this.props.status === Status.IDLE) {
      this.props.fetchAll(null);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentFolderId !== null &&
      this.props.currentFolderId === null
    ) {
      this.props.fetchAll(null);
    }
  }

  onFolderClick(id: number) {}

  onFolderDoubleClick(id: number) {
    this.props.fetchAll(id);
  }

  onFileDoubleClick(id: number) {
    this.props.openOverlay({
      overlayType: OverlayType.FILE_PREVIEW,
      overlayData: { id },
    });
  }

  onFileClick(id: number) {}

  onFolderDetailsClick(id: number) {
    this.props.setActive({
      type: DataType.FOLDER,
      id,
    });
  }

  onFileDetailsClick(id: number) {
    this.props.setActive({
      type: DataType.FILE,
      id,
    });
  }

  async onDropFolder(data: any) {
    const selected = this.props.folders.find(
      (folder) => folder.id === data.draggedElementId
    );

    console.log(data);

    console.log(selected);

    if (!selected) {
      return;
    }

    const payload = {
      id: selected.id,
      body: {
        name: selected.name,
        description: selected.description,
        parentFolderId: data.hoveredElementId,
      },
    };

    this.props.updateFolder(payload);
  }

  async onDropFile(data: any) {}

  async onDrop(e: React.DragEvent<HTMLDivElement>) {
    this.preventDefaults(e);
    const data = e.dataTransfer.items;

    const fileEntries = await this.traverseDirectoryTree(data);

    const toEnqueue = [];

    for (let i = 0; i < fileEntries.length; i++) {
      const { entry, parentFolderId } = fileEntries[i];

      const file = await this.getFileFromEntry(entry as FileSystemFileEntry);

      if (!file) {
        return null;
      }

      const url = URL.createObjectURL(
        new Blob([file], {
          type: file.type,
        })
      );

      toEnqueue.push({
        name: file.name,
        type: file.type,
        size: file.size,
        parentFolderId,
        url,
        progress: 0,
      });
    }

    this.props.enqueue({
      items: toEnqueue,
    });

    this.props.openUploadQueue();
  }

  async getFileFromEntry(entry: FileSystemFileEntry): Promise<File | null> {
    try {
      return await new Promise((resolve, reject) => {
        entry.file(resolve, reject);
      });
    } catch (err) {
      return null;
    }
  }

  async traverseDirectoryTree(dataTransferItemList: DataTransferItemList) {
    /**
     * Since we can drop a folder we need to recursively
     * create queue files from all folders
     * and create folders
     */

    const toEnqueue = [];
    const queue: QueueEntry[] = [];

    for (let i = 0; i < dataTransferItemList.length; i++) {
      const entry = dataTransferItemList[i].webkitGetAsEntry();

      if (entry !== null) {
        queue.push({
          entry,
          parentFolderId: this.props.currentFolderId,
        });
      }
    }

    while (queue.length > 0) {
      const item = queue.shift();

      if (!item) {
        continue;
      }

      const { entry, parentFolderId } = item;

      if (entry.isFile) {
        toEnqueue.push({
          type: DataType.FILE,
          entry,
          parentFolderId,
        });
      } else if (entry.isDirectory) {
        const { id } = await this.props
          .createFolder({
            name: entry.name,
            description: null,
            parentFolderId,
          })
          .unwrap();

        const entries = await this.readAllDirectoryEntries(
          (entry as FileSystemDirectoryEntry).createReader(),
          id
        );

        queue.push(...entries);
      }
    }

    return toEnqueue;
  }

  async readAllDirectoryEntries(
    directoryReader: FileSystemDirectoryReader,
    parentFolderId: number | null
  ) {
    const entries: QueueEntry[] = [];
    let readEntries = await this.readEntries(directoryReader);

    while (readEntries.length > 0) {
      readEntries.forEach((entry) =>
        entries.push({
          entry,
          parentFolderId,
        })
      );

      readEntries = await this.readEntries(directoryReader);
    }

    return entries;
  }

  async readEntries(
    directoryReader: FileSystemDirectoryReader
  ): Promise<FileSystemEntry[]> {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      return [];
    }
  }

  preventDefaults(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { folders, files } = this.props;

    return (
      <div
        className="dataview"
        onDragEnter={(e) => this.preventDefaults(e)}
        onDragOver={(e) => this.preventDefaults(e)}
        onDragLeave={(e) => this.preventDefaults(e)}
        onDrop={(e) => this.onDrop(e)}
      >
        <FolderList
          dropzone={false}
          onDrop={(data) => this.onDropFolder(data)}
          showDetails={true}
          folders={folders}
          onFolderDetailsClick={(id) => this.onFolderDetailsClick(id)}
          onFolderClick={(id) => this.onFolderClick(id)}
          onFolderDoubleClick={(id) => this.onFolderDoubleClick(id)}
        />
        <FileList
          dropzone={false}
          onDrop={(data) => this.onDropFile(data)}
          files={files}
          onFileDoubleClick={(id) => this.onFileDoubleClick(id)}
          onFileClick={(id) => this.onFileClick(id)}
          onFileDetailsClick={(id) => this.onFileDetailsClick(id)}
        />
      </div>
    );
  }
}

export default connector(DataList);
