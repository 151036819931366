import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import { formatBytes } from '../../../shared/utils/formatBytes';
import { formatDate } from '../../../shared/utils/formatDate';
import { DataType } from '../../../app/store/activeSlice';
import { fetchAll } from '../../../app/store/folderSlice';

interface IconSet {
  [key: string]: JSX.Element;
}

interface OwnProps {
  data: any;
  type: DataType;
}

const mapDispatchToProps = {
  fetchAll,
};

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

class InfoTable extends React.Component<Props> {
  getIconByDevice() {
    const icons: IconSet = {
      phone: <Icon icon={Icons.SMARTPHONE} size={26} />,
      tablet: <Icon icon={Icons.TABLET} size={26} />,
      desktop: <Icon icon={Icons.MONITOR} size={26} />,
    };

    return icons[this.props.data.device];
  }

  formatDeviceName() {
    const { device } = this.props.data;
    return device.charAt(0).toUpperCase() + device.slice(1);
  }

  getTitle() {
    return (
      (this.props.type === DataType.FILE ? 'Datoteka' : 'Mapa') + ' za Drive'
    );
  }

  getOwner() {
    return 'purplebear (jaz)';
  }

  getParentName() {
    return this.props.data.parentName || 'Moj disk';
  }

  changeFolder() {
    const id =
      this.props.type === DataType.FILE
        ? this.props.data.folderId
        : this.props.data.parentFolderId;

    // TODO: Change this to setContextId (which changes the currentFolderId)
    // and let the dataview component handle the fetching
    // so we prevent fetching if the wanted id matches the context id

    this.props.fetchAll(id);
  }

  render() {
    const { size, createdAt, updatedAt, description } = this.props.data;

    return (
      <>
        <div className="details-info">
          <div className="info-element">
            <div className="info-key">Naprava</div>
            <div className="info-value" style={{ fontWeight: 600 }}>
              {this.getIconByDevice()}
              <span>{this.formatDeviceName()}</span>
            </div>
          </div>
          <div className="info-element">
            <div className="info-key">Vrsta</div>
            <div className="info-value">
              <span>{this.getTitle()}</span>
            </div>
          </div>
          {this.props.type === DataType.FILE && (
            <div className="info-element">
              <div className="info-key">Velikost</div>
              <div className="info-value">
                <span>{formatBytes(size)}</span>
              </div>
            </div>
          )}
          <div className="info-element">
            <div className="info-key">Mesto</div>
            <div
              className="info-value"
              style={{ fontWeight: 600, cursor: 'pointer' }}
              onClick={() => this.changeFolder()}
            >
              <Icon icon={Icons.FOLDER} size={26} />
              <span>{this.getParentName()}</span>
            </div>
          </div>
          <div className="info-element">
            <div className="info-key">Lastnik</div>
            <div className="info-value">
              <span>{this.getOwner()}</span>
            </div>
          </div>

          <div className="info-element">
            <div className="info-key">Ustvarjeno</div>
            <div className="info-value">
              <span>{formatDate(createdAt)}</span>
            </div>
          </div>
          <div className="info-element">
            <div className="info-key">Spremenjeno</div>
            <div className="info-value">
              <span>{formatDate(updatedAt)}</span>
            </div>
          </div>
        </div>
        <div className="details-description">{description}</div>
      </>
    );
  }
}

export default connector(InfoTable);
